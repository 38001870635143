import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserPost } from './register.model';
import { Observable } from 'rxjs';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(
    private httpClient: HttpClient,
    private snackBarService: SnackbarService
  ) {}

  public post = (user: UserPost): Observable<UserPost> => {
    return this.httpClient
      .post<UserPost>('user/client', JSON.stringify(user))
      .pipe();
  };
}
