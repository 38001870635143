import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  AbstractControl,
} from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FullNameValidator } from 'src/app/shared/validators/name-validator';
import { DateBirthValidator } from 'src/app/shared/validators/date-birth';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { DocumentValidator } from 'src/app/shared/validators/document';
import { PasswordValidator } from 'src/app/shared/validators/password';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { RegisterService } from './register.service';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInterceptor } from 'src/app/shared/services/interceptor/http-client-interceptor.service';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserPost } from './register.model';
import { Router } from '@angular/router';

import { getFormatsDatePickerProvider } from 'src/app/shared/providers/date-picker-format-provider';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass'],
  providers: [
    ...getFormatsDatePickerProvider(),
    provideNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
  ],
})
export class RegisterComponent {
  constructor(
    private service: RegisterService,
    private dateFormatService: DateFormatService,
    private snackbarService: SnackbarService,
    private router: Router
  ) {}

  public hide = true;
  public hideConfirmationPassword = true;
  public isLoading = false;

  public form: FormGroup = new FormGroup({
    fullName: new FormControl('', [
      Validators.required,
      FullNameValidator.onlyAlphabeticalCharactersAndSpaceAllowed,
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(70),
    ]),
    dateBirth: new FormControl('', [
      Validators.required,
      DateBirthValidator.validate,
    ]),
    cellphone: new FormControl('', [
      Validators.required,
      Validators.minLength(11),
    ]),
    document: new FormControl('', [
      Validators.required,
      DocumentValidator.validate,
    ]),
    password: new FormControl('', [
      Validators.required,
      PasswordValidator.validate,
    ]),
    confirmPassword: new FormControl('', [Validators.required]),
  });

  private getControl = (field: string): AbstractControl | null =>
    this.form.get(field);

  public fieldIsInvalid = (field: string): boolean => {
    const fieldControl = this.getControl(field);

    if (fieldControl) return fieldControl.invalid;
    return false;
  };

  public getErrorConfirmPassword = (): string => {
    const fieldControl = this.getControl('confirmPassword');

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';

      if (this.getControl('password')?.value !== fieldControl.value) {
        fieldControl.setErrors({ validate: true });
        return 'Campos de senha estão divergentes!';
      } else fieldControl.setErrors(null);
    }

    return '';
  };

  public getErrorPassword = (): string => {
    const fieldControl = this.getControl('password');

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';
      if (fieldControl.hasError('isInvalid'))
        return 'O campo deve conter no minímo 6 caracteres!';
    }

    return '';
  };

  public getErrorFullName = (): string => {
    const fieldControl = this.getControl('fullName');

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';
      if (fieldControl.hasError('onlyAlphabeticalCharactersAndSpaceAllowed'))
        return 'Campo inválido!';
    }

    return '';
  };

  public getErrorGenericMessage = (field: string): string => {
    const fieldControl = this.getControl(field);

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';

      if (fieldControl.invalid) return 'Campo inválido!';
    }

    return '';
  };

  public formIsInvalid = (): boolean => this.form.invalid;

  public submit = () => {
    if (!this.formIsInvalid()) {
      try {
        this.isLoading = true;
        this.service
          .post({
            cellphone: this.getControl('cellphone')?.value as string,
            fullName: this.getControl('fullName')?.value as string,
            dateBirth: this.dateFormatService.format(
              this.getControl('dateBirth')?.value,
              'YYYY-MM-DD'
            ),
            cpf: this.getControl('document')?.value as string,
            email: this.getControl('email')?.value as string,
            password: this.getControl('password')?.value as string,
            isDisabled: false,
          })
          .subscribe({
            next: (response: UserPost) => {
              this.isLoading = false;
              this.snackbarService.showMessageSuccess(
                'Cadastrado com sucesso!'
              );
              this.router.navigateByUrl(`/confirmation-contact/${response.id}`);
            },
            error: (error) => {
              this.isLoading = false;
              if (error && error?.status === 403) {
                this.snackbarService.showMessageError(
                  'Os dados preenchidos estão incompletos'
                );
                return;
              }

              if (error && error?.status === 409) {
                this.snackbarService.showMessageError(
                  'Os dados preenchidos já estão sendo utilizados em nosso sistema! Tente outro!'
                );
                return;
              }

              this.snackbarService.showMessageError();
              console.log('error - ', error);
            },
          });
      } catch (error) {
        this.snackbarService.showMessageError();
        console.log('error - ', error);
      } finally {
        this.isLoading = false;
      }
    }
  };
}
