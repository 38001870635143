import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  FormArray,
} from '@angular/forms';
import { Location } from '../../client/client.model';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { Location as AngularLocation } from '@angular/common';
import { Router } from '@angular/router';
import { EditAvailabilityService } from './edit-availability.service';
import { CalendarAvailability } from '../availability.model';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';

export type EditavailabilityState = {
  location: Location;
  date: Date;
};

@Component({
  selector: 'app-edit-availability',
  templateUrl: './edit-availability.component.html',
  styleUrls: ['./edit-availability.component.sass'],
})
export class EditAvailabilityComponent implements OnInit, OnDestroy {
  public title = '';
  public state: EditavailabilityState | null = null;
  public isLoading = false;
  public inputsOutputs = new FormArray<any>([]);
  public disabledPeriod = true;

  public form: FormGroup = new FormGroup({
    location: new FormControl([Validators.required]),
  });

  public locations: {
    name: string;
    value: Location;
  }[] = [];

  constructor(
    private service: EditAvailabilityService,
    private snackbarService: SnackbarService,
    private angularLocation: AngularLocation,
    private router: Router,
    private dateFormatService: DateFormatService
  ) {}

  public buildInputAndOutput = () => {
    if (!!this.state?.date && !!this.state.location) {
      this.title = `Editar entradas e saídas do dia ${this.dateFormatService.castDateForShortString(
        this.state.date
      )}`;

      this.service
        .getOnlyDay(this.state.location.id, this.state.date)
        .subscribe({
          next: (calendarAvailabilitys: CalendarAvailability[]) => {
            calendarAvailabilitys.forEach((calendarAvailability) => {
              const input = this.dateFormatService.format(
                this.dateFormatService.getDateByDateStringUTC(
                  calendarAvailability.startDate
                ),
                'LT'
              );
              const output = this.dateFormatService.format(
                this.dateFormatService.getDateByDateStringUTC(
                  calendarAvailability.endDate
                ),
                'LT'
              );

              this.inputsOutputs.push(
                new FormControl(input, [Validators.required])
              );
              this.inputsOutputs.push(
                new FormControl(output, [Validators.required])
              );
            });
          },
          error: (error) => {
            console.log(error);
            this.snackbarService.showMessageError();
          },
        });
    }
  };

  private initilaize = () => {
    this.buildInputAndOutput();
    this.buildLocations();
  };

  ngOnDestroy(): void {
    this.angularLocation.ngOnDestroy();
  }

  ngOnInit() {
    this.state = this.angularLocation.getState() as EditavailabilityState;

    if (!!this.state) this.initilaize();
    else this.router.navigateByUrl('/admin-area/availability');
  }

  private buildLocations = () => {
    const location = this.state?.location;
    if (!!location) {
      this.locations = [
        {
          name: `${location.street}, ${location.number} - ${location.district}, ${location.city} - ${location.state}`,
          value: location,
        },
      ];
      this.getControl('location')?.setValue(location);
      this.getControl('location')?.disable();
    }
  };

  private getControl = (field: string): AbstractControl | null =>
    this.form.get(field);

  public rangePeriodIsInvalid = () =>
    this.fieldIsInvalid('rangePeriodStart') &&
    this.fieldIsInvalid('rangePeriodEnd');

  public fieldIsInvalid = (field: string): boolean => {
    const fieldControl = this.getControl(field);

    if (fieldControl) return fieldControl.invalid;
    return false;
  };

  public getErrorGenericMessage = (field: string): string => {
    const fieldControl = this.getControl(field);

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';

      if (fieldControl.invalid) return 'Campo inválido!';
    }

    return '';
  };

  public fieldInputOrOutputInvalid = (control: AbstractControl) =>
    control.invalid;

  public formIsInvalid = () => {
    return this.form.invalid || this.inputsOutputs.invalid;
  };

  public submit = () => {
    if (!!this.state) {
      this.isLoading = true;

      const date = this.dateFormatService.getDateUTC(
        new Date(this.state?.date)
      );

      this.service
        .update(this.state?.location, this.inputsOutputs.getRawValue(), date)
        .subscribe({
          next: () => {
            this.isLoading = false;
            this.snackbarService.showMessageSuccess('Editado com sucesso!');
            this.angularLocation.ngOnDestroy();
            this.back();
          },
          error: (error) => {
            console.log(error);
            this.isLoading = false;
            this.snackbarService.showMessageSuccess(
              'Erro ao editar os horários!'
            );
          },
        });
    }
  };

  public deleteInputAndOutput = (index: number) => {
    this.inputsOutputs.controls.splice(index, 2);
  };

  public back = () => {
    this.angularLocation.ngOnDestroy();
    this.router.navigateByUrl('/admin-area/availability');
  };

  public createInputAndOutput = () => {
    this.inputsOutputs.push(new FormControl('', [Validators.required]));
    this.inputsOutputs.push(new FormControl('', [Validators.required]));
  };
}
