import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CalendarAvailability,
  CalendarAvailabilityRow,
} from './availability.model';
import { Observable } from 'rxjs';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';
import { Location } from '../client/client.model';

const URL_LOCATION = 'location';
const URL = 'calendar-availability';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  constructor(
    private httpClient: HttpClient,
    private dateFormatService: DateFormatService
  ) {}

  private twoDatesHaveSameDay = (date1: Date, date2: Date): boolean =>
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear();

  public castTimesToRows = (
    items: CalendarAvailability[],
    startDate: Date,
    endDate: Date
  ): CalendarAvailabilityRow[] => {
    const rows: CalendarAvailabilityRow[] = [];

    let dateCurrent: Date =
      startDate instanceof Date ? startDate : new Date(startDate);

    while (dateCurrent <= endDate) {
      const itemsFiltered: CalendarAvailability[] = items.filter(
        ({ startDate }) =>
          this.twoDatesHaveSameDay(
            this.dateFormatService.getDateByDateStringUTC(startDate),
            dateCurrent
          )
      );

      if (!!itemsFiltered && itemsFiltered?.length > 0) {
        const newRow: CalendarAvailabilityRow = {
          date: this.dateFormatService.getDateByDateStringUTC(
            itemsFiltered[0].startDate
          ),
          location: itemsFiltered[0].location,
          inputOutput: [],
        };

        for (const { endDate, startDate, id } of itemsFiltered)
          newRow.inputOutput.push({
            endDate: this.dateFormatService.getDateByDateStringUTC(endDate),
            startDate: this.dateFormatService.getDateByDateStringUTC(startDate),
            id,
          });

        rows.push(newRow);
      }

      dateCurrent.setDate(dateCurrent.getDate() + 1);
    }

    return rows;
  };

  public getPeriod = (
    startDate: Date,
    endDate: Date,
    locationId: number
  ): Observable<CalendarAvailability[]> => {
    const params = new HttpParams()
      .set('locationID', locationId)
      .set(
        'startDate',
        this.dateFormatService.castDateForShortString(startDate)
      )
      .set('endDate', this.dateFormatService.castDateForShortString(endDate));

    const urlPeriod = `${URL}/period`;

    return this.httpClient
      .get<CalendarAvailability[]>(urlPeriod, { params })
      .pipe();
  };

  public getAllLocations = (): Observable<Location[]> =>
    this.httpClient.get<Location[]>(URL_LOCATION).pipe();

  public delete = (locationId: number, date: Date) => {
    const params = new HttpParams()
      .set('locationID', locationId)
      .set('date', this.dateFormatService.castDateForShortString(date));

    return this.httpClient.delete(URL, { params }).pipe();
  };
}
