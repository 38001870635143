import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';
import { CalendarAvailability } from '../availability.model';
import { Location } from '../../client/client.model';
import { PeriodInputOutput } from '../add-availability/add-availability.model';
import { UpdatePeriodCalendarAvailabilityRequestDTO } from './edit-availability.model';

const URL_CALENDAR_AVAILABILITY = 'calendar-availability';

@Injectable({
  providedIn: 'root',
})
export class EditAvailabilityService {
  constructor(
    private httpClient: HttpClient,
    private dateFormatService: DateFormatService
  ) {}

  public getOnlyDay = (locationId: number, date: Date) => {
    const params = new HttpParams()
      .set('locationID', locationId)
      .set('date', this.dateFormatService.castDateForShortString(date));

    const urlOnlyDay = `${URL_CALENDAR_AVAILABILITY}/only-day`;

    return this.httpClient
      .get<CalendarAvailability[]>(urlOnlyDay, { params })
      .pipe();
  };

  private getDateByHours = (field: string, input: boolean) => {
    const hours = Number(field.split(':')[0]);
    const minutes = Number(field.split(':')[1]);
    const date = new Date(1999, 10, 10, hours, minutes, input ? 0 : 59);

    return this.dateFormatService.getDateUTC(date);
  };

  private castDataPeriodInDTO = (
    location: Location,
    inputsOrOutputs: string[],
    date: Date
  ): UpdatePeriodCalendarAvailabilityRequestDTO => {
    const periods: PeriodInputOutput[] = [];

    for (let i = 0; i < inputsOrOutputs.length; i += 2)
      periods.push({
        input: this.getDateByHours(inputsOrOutputs[i], true),
        output: this.getDateByHours(inputsOrOutputs[i + 1], false),
      });

    return {
      location,
      date,
      periods,
    };
  };

  public update = (location: Location, inputsOrOutputs: string[], date: Date) =>
    this.httpClient
      .put(
        URL_CALENDAR_AVAILABILITY,
        JSON.stringify(
          this.castDataPeriodInDTO(location, inputsOrOutputs, date)
        )
      )
      .pipe();
}
