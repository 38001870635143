import { DateFormatService } from './../../../../shared/services/date-format/date-format.service';
import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MAX_WIDTH_MEDIUM } from 'src/app/shared/validators/breakpoints';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Category, ServiceCategory } from '../../category/category.model';
import { CategoryService } from '../../category/category.service';
import { CalendarService } from '../calendar.service';
import { IEventCalendar, IUser } from '../calendar.model';
import { Location } from '../../client/client.model';
import { Observable } from 'rxjs';

type AddServiceDialogData = {
  location: Location;
  loadingCategory: (show: boolean) => {};
  loadingClient: (show: boolean) => {};
  loadingSubmit: (show: boolean) => {};
  showSuccessMessage: (message: string) => {};
  showErrorMessage: (message: string) => {};
  info?: IEventCalendar;
};

@Component({
  selector: 'app-add-event-dialog',
  templateUrl: './add-event-dialog.component.html',
  styleUrls: ['./add-event-dialog.component.sass'],
})
export class AddEventDialogComponent implements OnInit, AfterViewInit {
  public title = 'Adicionar evento';
  public isMediumTablet = false;
  public labelAction = 'Adicionar';
  public isEdit = false;
  public checked = true;
  public hourEndDate = '';

  public categories: {
    name: string;
    value: Category;
  }[] = [];
  public clients: {
    name: string;
    value: IUser;
  }[] = [];

  public services: {
    name: string;
    value: ServiceCategory;
  }[] = [];

  public isLoadingSubmitDialog = false;
  public isLoadingCategoryDialog = false;
  public isLoadingClientDialog = false;

  public clientForm = new FormControl<IUser | null>(null, [
    Validators.required,
  ]);

  public form: FormGroup = new FormGroup({
    category: new FormControl([Validators.required]),
    service: new FormControl([Validators.required]),
    clients: new FormControl([Validators.required]),
    startDate: new FormControl([Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<AddEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddServiceDialogData,
    private observer: BreakpointObserver,
    private categoryService: CategoryService,
    private calendarService: CalendarService,
    private dateFormatService: DateFormatService
  ) {}

  public pageIsLoading = () =>
    this.isLoadingCategoryDialog ||
    this.isLoadingClientDialog ||
    this.isLoadingSubmitDialog;

  private buildCategories = () => {
    this.isLoadingCategoryDialog = true;

    this.categoryService.getAll().subscribe({
      next: (categories) => {
        categories.forEach((category) =>
          this.categories.push({
            name: category.description,
            value: category,
          })
        );

        if (this.isEdit) {
          const category = this.categories.find((item) => {
            const existService = item.value?.services.find(
              (service) => service?.id === this.data.info?.service?.id
            );
            return !!existService;
          });

          if (!!category) {
            this.form.get('category')?.setValue(category?.value);

            this.onChangeCategory();
            const service = this.services.find(
              (item) => item.value?.id === this.data.info?.service?.id
            );
            this.form.get('service')?.setValue(service?.value);
          }
        }

        this.isLoadingCategoryDialog = false;
      },
      complete: () => {
        if (this.categories?.length === 0)
          this.data.showErrorMessage('');
        this.isLoadingCategoryDialog = false;
      },
    });
  };

  private buildClients = () => {
    this.isLoadingClientDialog = true;

    this.calendarService.getAllClients().subscribe({
      next: (clients) => {
        clients.forEach((client) => {
          let name = '';

          if (!!client.cellphone && client.cellphone !== '') {
            const cellphone = client.cellphone.split('');
            name = `${client.fullName} - (${cellphone[0]}${cellphone[1]}) ${cellphone[2]} ${cellphone[3]}${cellphone[4]}${cellphone[5]}${cellphone[6]} - ${cellphone[7]}${cellphone[8]}${cellphone[9]}${cellphone[10]}`;
          } else name = client.fullName;

          this.clients.push({
            name,
            value: client,
          });
        });
        if (this.isEdit) {
          const client = this.clients.find(
            (item) => item.value.id === this.data.info?.client?.id
          );

          if (!!client) this.form.get('clients')?.setValue(client.value);
        }

        this.isLoadingClientDialog = false;
      },
      complete: () => {
        if (this.clients?.length === 0)
          this.data.showErrorMessage('Não possui clientes cadastrados!');

        this.isLoadingClientDialog = false;
      },
    });
  };

  private buildStartDate = () => {
    if (!!this.data?.info?.startTime) {
      const date = this.dateFormatService.getDateByDateStringUTC(
        this.data?.info?.startTime
      );
      const startDateValue =
        date.getFullYear().toString() +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2) +
        'T' +
        date.toTimeString().slice(0, 5);
      this.form.get('startDate')?.setValue(startDateValue);
    }
  };

  private buildEditForm = () => {
    this.isEdit = true;
    this.title = 'Editar evento';
    this.labelAction = 'Editar';
  };

  private buildSaveForm = () => {
    this.getControl('service')?.disable();
  };

  ngOnInit() {
    if (!!this.data?.info) this.buildEditForm();
    else this.buildSaveForm();

    this.buildCategories();
    this.buildClients();
    this.buildStartDate();
  }

  ngAfterViewInit(): void {
    this.observer.observe([MAX_WIDTH_MEDIUM]).subscribe((res) => {
      this.isMediumTablet = res.matches;
    });
  }

  public fieldIsInvalid = (field: string): boolean => {
    const fieldControl = this.getControl(field);

    if (fieldControl) return fieldControl.invalid;
    return false;
  };

  public getControl = (field: string): AbstractControl<any, any> | null =>
    this.form.get(field);

  public getErrorGenericMessage = (field: string): string => {
    const fieldControl = this.getControl(field);

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';

      if (fieldControl.invalid) return 'Campo inválido!';
    }

    return '';
  };

  public formIsInvalid = (): boolean => {
    const startDate = this.getControl('startDate');

    const value = startDate?.value;
    const startDateIsInvalid =
      startDate?.invalid ||
      !value ||
      value === null ||
      isNaN(new Date(value)?.getTime());

    return startDateIsInvalid || this.form.invalid;
  };

  public submit = () => {
    this.isLoadingSubmitDialog = true;

    let handlerFunction: Observable<any>;

    const data = {
      id: this.isEdit && this.data.info?.id ? this.data.info?.id : -1,
      category: this.getControl('category')?.value as Category,
      idClient: (this.getControl('clients')?.value as IUser).id,
      location: this.data.location,
      service: this.getControl('service')?.value as ServiceCategory,
      startDateHour: this.dateFormatService.getDateUTC(
        new Date(this.getControl('startDate')?.value)
      ),
    };

    if (this.isEdit) handlerFunction = this.calendarService.put(data);
    else handlerFunction = this.calendarService.post(data);

    handlerFunction.subscribe({
      next: () => {
        this.data.showSuccessMessage(
          `Evento ${this.isEdit ? 'alterado' : 'adicionado'} com sucesso!`
        );
        this.close();
        this.isLoadingSubmitDialog = false;
      },
      error: (error) => {
        this.isLoadingSubmitDialog = false;

        if (error?.error?.message === 'CALENDAR_ERROR_002')
          this.data.showErrorMessage(
            'Este horário já está ocupado por outro evento! Marque para outro horário!'
          );
        else if (error?.error?.message === 'CALENDAR_ERROR_001')
          this.data.showErrorMessage(
            'Não é possível marcar neste horário pois não há disponibildiade de horário cadastrada!'
          );
        else {
          this.data.showErrorMessage('');
          console.log(error);
        }
      },
    });
  };

  public close = () => {
    this.dialogRef.close();
  };

  public onChangeCategory = () => {
    const category = this.getControl('category')?.value;
    if (!!category && category !== null && category !== '') {
      this.services = [];
      this.getControl('service')?.enable();
      (category as Category).services?.forEach((service) =>
        this.services.push({
          value: service,
          name: service.name,
        })
      );
    } else {
      this.services = [];
      this.getControl('service')?.disable();
    }
  };

  public onChangeService = () => this.onChangeStartDate();

  public onChangeStartDate = () => {
    const startDate = this.getControl('startDate');

    const value = startDate?.value;
    if (
      startDate?.valid &&
      !!value &&
      value !== null &&
      !isNaN(new Date(value)?.getTime())
    ) {
      const valueDate = new Date(value);
      const service = this.getControl('service');
      if (
        service?.valid &&
        !!service?.value &&
        service?.value !== null &&
        service?.value !== ''
      ) {
        const minutesService =
          (service.value as ServiceCategory).durationInSeconds / 60;
        const endDate = valueDate;
        endDate.setMinutes(endDate.getMinutes() + minutesService);
        this.hourEndDate = this.dateFormatService.format(endDate, 'LT');
        return;
      }
    }

    this.hourEndDate = '';
  };

  public cancelEvent = () => {
    this.isLoadingSubmitDialog = true;
    if (!!this.data?.info?.id)
      this.calendarService.cancel(this.data.info?.id).subscribe({
        next: () => {
          this.isLoadingSubmitDialog = false;
          this.data.showSuccessMessage('Evento cancelado com sucesso!');
          this.close();
        },
        error: (error: any) => {
          console.log(error);
          this.isLoadingSubmitDialog = false;
          this.data.showErrorMessage('Erro ao cancelar Evento!');
        },
      });
  };
}
