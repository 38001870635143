import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';

import { BreakpointObserver } from '@angular/cdk/layout';
import { MAX_WIDTH_FIXED_TEENY } from 'src/app/shared/validators/breakpoints';
import { CategoryService } from '../category.service';
import { ServiceCategory } from '../category.model';
import { Observable } from 'rxjs';
import { TimeService } from 'src/app/shared/utils/time-service';

type AddServiceDialogData = {
  idCategory?: number;
  showOrHideLoading: (show: boolean) => {};
  showSuccessMessage: (message: string) => {};
  showErrorMessage: (message: string) => {};
  service?: ServiceCategory;
};

@Component({
  selector: 'app-add-service-dialog',
  templateUrl: './add-service-dialog.component.html',
  styleUrls: ['./add-service-dialog.component.sass'],
})
export class AddServiceDialogComponent implements OnInit, AfterViewInit {
  public isLoading = false;
  public title = 'Adicionar serviço';
  public isTeenyMobile = false;
  public labelAction = 'Adicionar';
  public fileName = '';
  public isEdit = false;

  constructor(
    public dialogRef: MatDialogRef<AddServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddServiceDialogData,
    private observer: BreakpointObserver,
    private categoryService: CategoryService
  ) {}

  public form: FormGroup = new FormGroup({
    description: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    durationInSeconds: new FormControl('', [Validators.required]),
    paymentFormat: new FormControl('', [Validators.required]),
    valueMonetary: new FormControl('', [Validators.required]),
    image: new FormControl('', [Validators.required]),
  });

  private buildFormAndLabels = () => {
    this.isEdit = !!this.data?.service;

    if (!!this.data?.service) {
      const {
        description,
        name,
        durationInSeconds,
        paymentFormat,
        valueMonetary,
        image,
      } = this.data.service;

      this.form.setValue({
        description,
        name,
        durationInSeconds: TimeService.castSecondsInHours(durationInSeconds),
        paymentFormat,
        valueMonetary,
        image,
      });
      this.labelAction = 'Editar';
      this.title = 'Editar serviço';
    } else {
      this.labelAction = 'Adicionar';
      this.title = 'Adicionar serviço';
    }
  };

  private showLoading = (show: boolean) => (this.isLoading = show);

  ngOnInit() {
    this.buildFormAndLabels();
  }

  ngAfterViewInit(): void {
    this.observer.observe([MAX_WIDTH_FIXED_TEENY]).subscribe((res) => {
      this.isTeenyMobile = res.matches;
    });
  }

  private getControl = (field: string): AbstractControl | null =>
    this.form.get(field);

  public getErrorGenericMessage = (field: string): string => {
    const fieldControl = this.getControl(field);

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';

      if (fieldControl.invalid) return 'Campo inválido!';
    }

    return '';
  };

  public fieldIsInvalid = (field: string): boolean => {
    const fieldControl = this.getControl(field);

    if (fieldControl) return fieldControl.invalid;
    return false;
  };

  public formIsInvalid = (): boolean => this.form.invalid;

  public submit = () => {
    this.showLoading(true);
    const service = this.form.getRawValue();

    let response: Observable<any>;

    if (this.isEdit)
      response = this.categoryService.updateService(
        this.data.idCategory ?? -1,
        {
          id: this.data?.service?.id,
          ...service,
          durationInSeconds: TimeService.castHoursInSeconds(
            service.durationInSeconds
          ),
        }
      );
    else {
      response = this.categoryService.insertService(
        this.data.idCategory ?? -1,
        {
          ...service,
          id: -1,
          durationInSeconds: TimeService.castHoursInSeconds(
            service.durationInSeconds
          ),
        }
      );
    }

    response.subscribe({
      next: () => {
        this.data.showSuccessMessage(
          `${this.isEdit ? 'Editado' : 'Adicionado'} serviço com sucesso!`
        );

        this.close();
      },
      complete: () => {
        this.showLoading(false);
      },
      error: () => {
        this.showLoading(false);
        this.data.showErrorMessage('');
      },
    });
  };

  close = () => this.dialogRef.close();

  onFileSelected(event: any) {
    event.preventDefault();
    const file: File = event.target.files[0];

    this.fileName = file.name;

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.form.get('image')?.setValue(reader.result);
    };
  }
}
