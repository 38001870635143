import { AbstractControl, ValidationErrors } from '@angular/forms';

export class FullNameValidator {
  static onlyAlphabeticalCharactersAndSpaceAllowed(
    control: AbstractControl
  ): ValidationErrors | null {
    const regex = `(\\b[A-Z]{1}[a-z]+)( )([A-Z]{1}[a-z]+\\b)`;

    if (!(control.value as string).match(regex))
      return { onlyAlphabeticalCharactersAndSpaceAllowed: true };

    return null;
  }
}
