import { AbstractControl, ValidationErrors } from '@angular/forms';

export class PasswordValidator {
  static validate(control: AbstractControl): ValidationErrors | null {
    const passwordInvalid = { isInvalid: true };

    const password = control.value;

    if (typeof password !== 'string') return passwordInvalid;

    if (password.length < 6) return passwordInvalid;

    return null;
  }
}
