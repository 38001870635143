import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { SessionUserService } from '../session-user/session-user.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../snackbar/snackbar.service';

// const URL_HOST = 'http://localhost:8080';
const URL_HOST = 'https://backend.rosiane-almeida.com';

@Injectable({
  providedIn: 'root',
})
export class HttpClientInterceptor implements HttpInterceptor {
  constructor(
    private sessionUserService: SessionUserService,
    private router: Router,
    private snackbarService: SnackbarService
  ) {}

  private getHeadersForClient = (): HttpHeaders => {
    const token = this.sessionUserService.getSessionTokenInBrowser();

    if (token)
      return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      });

    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  };

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = '';

    if (req.url.includes(URL_HOST)) url = req.url;
    else url = `${URL_HOST}/${req.url}`;

    const apiReq = req.clone({
      url,
      headers: this.getHeadersForClient(),
    });

    return next.handle(apiReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.sessionUserService.removeSessionUser();
          this.router.navigateByUrl('/login');
          setTimeout(() => {
            this.snackbarService.dismiss();
            this.snackbarService.showMessageError(
              'Sessão expirada. Acesse novamente!'
            );
          }, 1000);
        }

        throw error;
      })
    );
  }
}
