import {
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MAX_WIDTH_FIXED_SIDE_BAR } from 'src/app/shared/validators/breakpoints';

import {
  FormControl,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { AboutService } from './about.service';
import { IAbout } from './about.model';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass'],
})
export class AboutComponent implements AfterViewInit, OnInit {
  public id?: number;
  public menuIsSideBar: boolean = false;
  public fileName = '';
  public isLoading: boolean = false;

  constructor(
    private observer: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private service: AboutService,
    private snackbarService: SnackbarService
  ) {}

  private buildValuesForm = () => {
    this.isLoading = true;

    this.service.get().subscribe({
      next: (about: IAbout) => {
        if (!!about && !!about.id) {
          this.fileName = 'Foto atual.png';
          this.id = about.id;
          this.getControl('name')?.setValue(about.name);
          this.getControl('description')?.setValue(about.description);
          this.getControl('subDescription')?.setValue(about.subDescription);
          this.getControl('imageURL')?.setValue(about.image);
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.snackbarService.showMessageError();
        this.isLoading = false;
      },
    });
  };

  ngOnInit(): void {
    this.buildValuesForm();
  }

  public form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    subDescription: new FormControl('', [Validators.required]),
    imageURL: new FormControl('', [Validators.required]),
  });

  private getControl = (field: string): AbstractControl | null =>
    this.form.get(field);

  public getErrorGenericMessage = (field: string): string => {
    const fieldControl = this.getControl(field);

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';

      if (fieldControl.invalid) return 'Campo inválido!';
    }

    return '';
  };

  public fieldIsInvalid = (field: string): boolean => {
    const fieldControl = this.getControl(field);

    if (fieldControl) return fieldControl.invalid;
    return false;
  };

  ngAfterViewInit() {
    this.observer
      .observe([MAX_WIDTH_FIXED_SIDE_BAR])
      .subscribe((res) => (this.menuIsSideBar = res.matches));

    this.cdRef.detectChanges();
  }

  onFileSelected(event: any) {
    event.preventDefault();
    const file: File = event.target.files[0];

    this.fileName = file.name;

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.form.get('imageURL')?.setValue(reader.result);
    };
  }

  submit = () => {
    this.isLoading = true;

    this.service
      .post({
        id: this.id,
        description: this.getControl('description')?.value,
        image: this.getControl('imageURL')?.value,
        name: this.getControl('name')?.value,
        subDescription: this.getControl('subDescription')?.value,
      })
      .subscribe({
        next: (response: IAbout) => {
          this.id = response.id;
          this.isLoading = false;
          this.snackbarService.showMessageSuccess(
            'Seus dados foram atualizados com sucesso!'
          );
        },
        error: (error) => {
          this.isLoading = false;
          this.snackbarService.showMessageError();
        },
      });
  };

  formIsInvalid = () => this.form.invalid;
}
