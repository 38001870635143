<h1 mat-dialog-title class="event__title">
  {{ getTitle() }}
</h1>
<div mat-dialog-content class="event__dialog">
  <mat-accordion>
    <mat-expansion-panel
      *ngFor="let item of data.events"
      class="event__dialog-panel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="event__dialog-panel__title">
          <p
            class="event__dialog-panel__status"
            [ngClass]="getClassForStatus(item)"
          >
            {{ getTextForStatus(item) }}
          </p>
          {{
            nameServiceIsLarge(item.nameService)
              ? item.nameService.split(" ")[0]
              : item.nameService
          }}
        </mat-panel-title>

        <mat-panel-description
          *ngIf="!isTablet"
          class="event__dialog-panel__description"
        >
          Marcado para {{ castDateInStringFormat(item.startTime) }} em
          {{ item.nameLocation.city }} - {{ item.nameLocation.state }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <p
        *ngIf="nameServiceIsLarge(item.nameService)"
        class="event__dialog-panel__content"
      >
        Serviço: {{ item.nameService }}
      </p>

      <p
        *ngIf="nameServiceIsLarge(item.nameService)"
        class="event__dialog-panel__divider"
      ></p>

      <p *ngIf="isTablet" class="event__dialog-panel__content">
        Marcado para {{ castDateInStringFormat(item.startTime) }}.
      </p>

      <p *ngIf="isTablet" class="event__dialog-panel__divider"></p>

      <p class="event__dialog-panel__content">
        Finalizado ás {{ castDateInStringFormat(item.endTime) }}
      </p>

      <p class="event__dialog-panel__divider"></p>

      <p class="event__dialog-panel__content">
        Serviço feito em: {{ getLocation(item.nameLocation) }}
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="close()" class="event__dialog-actions">
    Fechar
  </button>
</div>
