export interface UserAuthenticationRequest {
  documentOrEmail: string;
  password: string;
}

export enum StatusUser {
  VALID_CREDENTIALS = 'VALID_CREDENTIALS',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  UNCONFIRMED_CONTACT = 'UNCONFIRMED_CONTACT',
}

export enum UserTypeLogin {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface UserAuthenticationResponse {
  status: StatusUser;
  token?: string;
  newUserID: number;
  userType: UserTypeLogin;
}
