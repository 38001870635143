<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>
<h1 mat-dialog-title class="add-service-dialog__title">{{ title }}</h1>
<div mat-dialog-content class="add-service-dialog__content" [ngClass]="{ 'add-service-dialog__loading': isLoading }">
  <form [formGroup]="form" class="add-service-dialog__content-form">
    <mat-form-field class="add-service-dialog__content-form__name">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="fieldIsInvalid('name')" class="mat-error">{{
        getErrorGenericMessage("name")
      }}</mat-error>
    </mat-form-field>

    <div class="add-service-dialog__content-form__group">
      <mat-form-field class="add-service-dialog__content-form__description">
        <mat-label>Descrição</mat-label>
        <textarea
          cdkTextareaAutosize
          matInput
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="20"
          formControlName="description"
        ></textarea>
        <mat-error *ngIf="fieldIsInvalid('description')" class="mat-error">{{
          getErrorGenericMessage("description")
        }}</mat-error>
      </mat-form-field>
    </div>

    <div class="add-service-dialog__content-form__group">
      <mat-form-field class="add-service-dialog__content-form__group-duration">
        <mat-label>Duração</mat-label>
        <input matInput type="time" formControlName="durationInSeconds" />
        <mat-error *ngIf="fieldIsInvalid('durationInSeconds')" class="mat-error">{{
          "Preencha este campo!"
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Valor</mat-label>
        <span matPrefix>R$ &nbsp;</span>
        <input
          placeholder="500,00"
          matInput
          formControlName="valueMonetary"
          type="number"
        />
        <mat-error *ngIf="fieldIsInvalid('valueMonetary')" class="mat-error">{{
          getErrorGenericMessage("valueMonetary")
        }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Método de pagamento</mat-label>
        <input
          matInput
          formControlName="paymentFormat"
          placeholder="Ex: Parcelado em até 5x e PIX"
        />
        <mat-error *ngIf="fieldIsInvalid('paymentFormat')" class="mat-error">{{
          getErrorGenericMessage("paymentFormat")
        }}</mat-error>
      </mat-form-field>
    </div>

    <!-- <div class="add-service-dialog__content-form__group">

    </div> -->

    <div class="input-file-upload">
      <input
        type="file"
        class="file-input"
        [accept]="'image/png,image/jpg,image/jpeg'"
        (change)="onFileSelected($event)"
        #fileUpload
      />

      <div class="file-upload">
        <p [ngStyle]="{ color: fileName ? 'black' : 'red' }">
          {{ fileName || "Seleciona a sua foto" }}
        </p>

        <button
          mat-flat-button
          color="accent"
          (click)="$event.preventDefault(); fileUpload.click()"
        >
          Anexar nova foto
        </button>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions [align]="isTeenyMobile ? 'center' : 'end'">
  <button
    mat-raised-button
    (click)="submit()"
    class="add-service-dialog__actions"
    color="accent"
    [disabled]="formIsInvalid()"
  >
    {{ labelAction }}
  </button>
  <button
    mat-raised-button
    (click)="close()"
    class="add-service-dialog__actions"
  >
    Cancelar
  </button>
</div>
