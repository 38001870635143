import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  InsertPeriodCalendarAvailabilityRequestDTO,
  PeriodInputOutput,
  ValidatePeriodDateEventRequestDTO,
} from './add-availability.model';
import { Location } from '../../client/client.model';

const URL_LOCATION = 'location';
const URL_CALENDAR_AVAILABILITY = 'calendar-availability';

@Injectable({
  providedIn: 'root',
})
export class AddAvailabilityService {
  constructor(
    private httpClient: HttpClient,
    private dateFormatService: DateFormatService
  ) {}

  public getAllLocations = (): Observable<Location[]> =>
    this.httpClient.get<Location[]>(URL_LOCATION).pipe();

  public periodAllowedInsertNewavailability = (
    data: ValidatePeriodDateEventRequestDTO
  ) => {
    return this.httpClient
      .post(
        `${URL_CALENDAR_AVAILABILITY}/validate/period`,
        JSON.stringify(data)
      )
      .pipe();
  };

  private getDateByHours = (field: string, input: boolean): Date => {
    const hours = Number(field.split(':')[0]);
    const minutes = Number(field.split(':')[1]);
    const date = new Date(1999, 10, 10, hours, minutes, input ? 0 : 59);

    return this.dateFormatService.getDateUTC(date);
  };

  private castDataPeriodInDTO = (
    rangePeriodStart: Date,
    rangePeriodEnd: Date,
    location: Location,
    inputsOrOutputs: string[]
  ): InsertPeriodCalendarAvailabilityRequestDTO => {
    const periods: PeriodInputOutput[] = [];

    for (let i = 0; i < inputsOrOutputs.length; i += 2)
      periods.push({
        input: this.getDateByHours(inputsOrOutputs[i], true),
        output: this.getDateByHours(inputsOrOutputs[i + 1], false),
      });

    return {
      periods,
      endDate: rangePeriodEnd,
      initialDate: rangePeriodStart,
      location,
    };
  };

  public insertPeriod = ({
    inputsOrOutputs,
    location,
    rangePeriodEnd,
    rangePeriodStart,
  }: {
    rangePeriodStart: Date;
    rangePeriodEnd: Date;
    location: Location;
    inputsOrOutputs: string[];
  }) =>
    this.httpClient
      .post(
        URL_CALENDAR_AVAILABILITY,
        JSON.stringify(
          this.castDataPeriodInDTO(
            rangePeriodStart,
            rangePeriodEnd,
            location,
            inputsOrOutputs
          )
        )
      )
      .pipe();
}
