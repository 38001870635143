<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>
<div
  class="container-calendar"
  [ngClass]="{ 'container-calendar__loading': isLoading }"
>
  <h1 class="container-calendar__title">Agenda</h1>

  <h4 class="container-calendar__sub-title">
    Visualize os eventos de sua agenda selecionando sua localização:
  </h4>

  <form [formGroup]="form" ngClass="container-calendar__form">
    <mat-form-field ngClass="container-calendar__form-location">
      <mat-label>Localização</mat-label>
      <mat-select formControlName="location" required>
        <mat-option *ngFor="let location of locations" [value]="location.value">
          {{ location.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      mat-raised-button
      class="container-calendar__form-add"
      color="accent"
      [disabled]="formIsInvalid()"
      (click)="showAddEventDialog()"
    >
      Adicionar evento
    </button>
  </form>

  <full-calendar [options]="calendarOptions"></full-calendar>
</div>
