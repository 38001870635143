import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  AdminCreateClientRequest,
  AdminUpdateClientRequest,
  RowClientByAdmin,
} from './client.model';

const URL = 'event';
const URL_ADMIN_CLIENT = 'user/admin/client';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private httpClient: HttpClient) {}

  public getAll = (
    index: number,
    max: number
  ): Observable<RowClientByAdmin[]> => {
    const params = new HttpParams().set('index', index).set('max', max);

    return this.httpClient.get<RowClientByAdmin[]>(URL, { params }).pipe();
  };

  public create = (data: AdminCreateClientRequest) => {
    return this.httpClient.post(URL_ADMIN_CLIENT, data).pipe();
  };

  public update = (data: AdminUpdateClientRequest) => {
    return this.httpClient.put(URL_ADMIN_CLIENT, data).pipe();
  };
}
