import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';
import { IEventCalendar, IUser, InsertEventCalendar } from './calendar.model';
import { Location } from '../client/client.model';

const URL = 'event';
const URL_LOCATION = 'location';
const URL_ADMIN_CLIENT = 'user/admin/client';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(
    private dateFormatService: DateFormatService,
    private httpClient: HttpClient
  ) {}

  public getAll = (
    locationID: number,
    startDate: Date,
    endDate: Date
  ): Observable<IEventCalendar[]> => {
    const startDateString =
      this.dateFormatService.castDateForShortString(startDate);
    const endDateString =
      this.dateFormatService.castDateForShortString(endDate);

    const params = new HttpParams()
      .set('locationID', locationID)
      .set('startDate', startDateString)
      .set('endDate', endDateString);
    const url = `${URL}/calendar`;

    return this.httpClient.get<IEventCalendar[]>(url, { params }).pipe();
  };

  public getAllLocations = (): Observable<Location[]> =>
    this.httpClient.get<Location[]>(URL_LOCATION).pipe();

  public getAllClients = (): Observable<IUser[]> =>
    this.httpClient.get<IUser[]>(URL_ADMIN_CLIENT).pipe();

  public post = (data: InsertEventCalendar) =>
    this.httpClient.post(URL, data).pipe();

  public put = (data: InsertEventCalendar) =>
    this.httpClient.put(URL, data).pipe();

  public cancel = (id: number) => {
    const url = `${URL}/cancel/${id}`;
    return this.httpClient.put(url, null).pipe();
  };
}
