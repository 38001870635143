import { AbstractControl, ValidationErrors } from '@angular/forms';

export class DateBirthValidator {
  static validate(control: AbstractControl): ValidationErrors | null {
    const dateOfBirth = control.value;

    const dateNow = new Date();
    const birthDate = new Date(dateOfBirth);

    let age = dateNow.getFullYear() - birthDate.getFullYear();
    const monthDateBirth = dateNow.getMonth() - birthDate.getMonth();

    if (
      monthDateBirth < 0 ||
      (monthDateBirth === 0 && dateNow.getDate() < birthDate.getDate())
    )
      age--;

    if (isNaN(dateOfBirth) || age < 16 || age > 100)
      return { invalidDateOfBirth: true };

    return null;
  }
}
