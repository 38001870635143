<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>

<div
  class="container-about"
  [ngClass]="{
          'container-about__desktop': !menuIsSideBar,
          'container-about__menu_dinamic': menuIsSideBar,
          'container-about__loading': isLoading,
        }"
>
  <h1 class="container-about__title">Informações do Sobre</h1>

  <form [formGroup]="form">
    <h4 class="container-about__sub-title">
      O que você deseja que os clientes saibam de você? Preencha abaixo!
    </h4>

    <div class="two-inputs">
      <mat-form-field>
        <mat-icon matTextPrefix>badge</mat-icon>
        <mat-label>Nome completo</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="fieldIsInvalid('name')" class="mat-error">{{
          getErrorGenericMessage("name")
        }}</mat-error>
      </mat-form-field>

      <div class="input-file-upload">
        <input
          type="file"
          class="file-input"
          [accept]="'image/png,image/jpg,image/jpeg'"
          (change)="onFileSelected($event)"
          #fileUpload
        />

        <div class="file-upload">
          <p [ngStyle]="{ color: fileName ? 'black' : 'red' }">
            {{ fileName || "Seleciona a sua foto" }}
          </p>

          <button
            mat-flat-button
            color="accent"
            (click)="$event.preventDefault(); fileUpload.click()"
          >
            Anexar nova foto
          </button>
        </div>
      </div>
    </div>

    <mat-form-field class="text-area">
      <mat-label>Descrição</mat-label>
      <textarea
        cdkTextareaAutosize
        matInput
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="20"
        formControlName="description"
      ></textarea>
      <mat-error *ngIf="fieldIsInvalid('description')" class="mat-error">{{
        getErrorGenericMessage("description")
      }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Legenda para botão de agendamento</mat-label>
      <textarea
        cdkTextareaAutosize
        matInput
        formControlName="subDescription"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="20"
      ></textarea>
      <mat-error *ngIf="fieldIsInvalid('subDescription')" class="mat-error">{{
        getErrorGenericMessage("subDescription")
      }}</mat-error>
    </mat-form-field>

    <button
      mat-raised-button
      class="submit"
      color="accent"
      (click)="submit()"
      [disabled]="formIsInvalid()"
    >
      Salvar
    </button>
  </form>
</div>
