import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventByClient } from '../../admin-area/client/client.model';

const URL = 'event/user';

@Injectable({
  providedIn: 'root',
})
export class HistoricService {
  constructor(private httpClient: HttpClient) {}

  public getEventsByUser = (idUser: number): Observable<EventByClient[]> => {
    const params = new HttpParams().set('id', idUser);
    return this.httpClient.get<EventByClient[]>(URL, { params }).pipe();
  };
}
