export type TypeHeader = {
  class?: string;
  linkRedirect: string;
  icon?: string;
  label: string;
};

export const getHeadersAdmin = (isDesktop: boolean): TypeHeader[] => [
  {
    class: isDesktop ? 'menu-desktop__button' : 'menu-button',
    linkRedirect: 'admin-area/calendar',
    icon: 'event',
    label: 'Agenda',
  },
  {
    class: isDesktop ? 'menu-desktop__button' : 'menu-button',
    linkRedirect: 'admin-area/availability',
    icon: 'more_time',
    label: 'Disponibilidade',
  },
  {
    class: isDesktop ? 'menu-desktop__button' : 'menu-button',
    linkRedirect: 'admin-area/category',
    icon: 'apps',
    label: 'Categorias',
  },
  {
    class: isDesktop ? 'menu-desktop__button' : 'menu-button',
    linkRedirect: 'admin-area/client',
    icon: 'group',
    label: 'Clientes',
  },
  {
    class: isDesktop ? 'menu-desktop__button' : 'menu-button',
    linkRedirect: 'admin-area/about',
    icon: 'contact_mail',
    label: 'Sobre',
  },
];

export const getHeadersClient = (isDesktop: boolean): TypeHeader[] => [
  {
    class: isDesktop ? 'menu-desktop__button' : 'menu-button',
    linkRedirect: 'user/home',
    icon: 'home',
    label: 'Inicio',
  },
  {
    class: isDesktop ? 'menu-desktop__button' : 'menu-button',
    linkRedirect: 'user/scheduling',
    icon: 'more_time',
    label: 'Agendar horário',
  },
  {
    class: isDesktop ? 'menu-desktop__button' : 'menu-button',
    linkRedirect: 'user/historic',
    icon: 'history',
    label: 'Histórico',
  },
  {
    class: isDesktop ? 'menu-desktop__button' : 'menu-button',
    linkRedirect: 'user/services',
    icon: 'format_list_bulleted',
    label: 'Serviços',
  },
];
