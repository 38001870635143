<h2 mat-dialog-title>Confirme seus dados:</h2>
<mat-dialog-content class="mat-typography">
  <p>Serviço: {{ data.service?.name }}</p>
  <p>Data: {{ castDateInStringFormat(data.date) }}</p>
  <p>Duração: {{ castDurationForLabel(data.service?.durationInSeconds) }}</p>
  <p>
    {{
      getInputOrOutputFormat(
        data.period.input.toString(),
        data.period.output.toString()
      )
    }}
  </p>
  <p>Local: {{ getLocation(data.location) }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    mat-dialog-close
    color="primary"
    class="dialog-confirmation-action"
  >
    Cancelar
  </button>
  <button
    mat-raised-button
    class="dialog-confirmation-action"
    color="accent"
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="data.submit()"
  >
    Confirmar agendamento
  </button>
</mat-dialog-actions>
