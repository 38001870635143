import { createAction } from '@ngrx/store';
import { SessionDataUserType } from 'src/app/shared/services/session-user/session-user.service';

export const insert = createAction(
  '[User Session] Insert',
  (sessionDataUserType: SessionDataUserType) => sessionDataUserType
);

export const reset = createAction(
  '[User Session] Reset'
)
