import { AfterViewInit, Component, OnInit } from '@angular/core';
import { EventByClient, Location } from '../../admin-area/client/client.model';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MAX_WIDTH_MEDIUM } from 'src/app/shared/validators/breakpoints';
import { HistoricService } from './historic.service';
import { SessionDataUserType } from 'src/app/shared/services/session-user/session-user.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.sass'],
})
export class HistoricComponent implements AfterViewInit, OnInit {
  public isTablet = false;

  public events: EventByClient[] = [];
  private userSessionSubscription: Subscription | null = null;

  constructor(
    private dateFormatService: DateFormatService,
    private observer: BreakpointObserver,
    private historicService: HistoricService,
    private store: Store<{ userSession: SessionDataUserType }>
  ) {}

  ngAfterViewInit(): void {
    this.observer.observe([MAX_WIDTH_MEDIUM]).subscribe((res) => {
      this.isTablet = res.matches;
    });
  }

  private getEvents = () => {
    this.userSessionSubscription = this.store
      .select('userSession')
      .subscribe((data: any) => {
        if (data.value !== null)
          this.historicService
            .getEventsByUser(data.value.userId)
            .subscribe((events) => (this.events = events));
      });
  };

  ngOnInit(): void {
    if (this.userSessionSubscription)
      this.userSessionSubscription.unsubscribe();
    this.getEvents();
  }

  castDateInStringFormat = (date: Date) =>
    this.dateFormatService.format(date.toString(), 'LLL');

  private eventCancled = (row: EventByClient) => row.canceled;

  private eventSuccess = (row: EventByClient) =>
    new Date(row.endTime) < new Date();

  getClassForStatus = (row: EventByClient): string => {
    if (this.eventCancled(row)) return 'historic__dialog-panel__status-error';
    if (this.eventSuccess(row)) return 'historic__dialog-panel__status-success';

    return 'historic__dialog-panel__status-pending';
  };

  getTextForStatus = (row: EventByClient) => {
    if (this.eventCancled(row)) return 'Cancelado';
    if (this.eventSuccess(row)) return 'Concluído';

    return 'Pendente';
  };

  nameServiceIsLarge = (name: string) => name.length > 15;

  getLocation = (location: Location) =>
    `${location.street}, ${location.number} - ${location.district}, ${location.city} - ${location.state}`;
}
