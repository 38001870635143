<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>
<h1 mat-dialog-title class="add-category-dialog__title">{{ title }}</h1>
<div
  mat-dialog-content
  class="add-category-dialog__content"
  [ngClass]="{ 'add-category-dialog__loading': isLoading }"
>
  <form [formGroup]="form" class="add-category-dialog__content-form">
    <mat-form-field class="add-category-dialog__content-form__name">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="fieldIsInvalid('name')" class="mat-error">{{
        getErrorGenericMessage("name")
      }}</mat-error>
    </mat-form-field>
  </form>
  <mat-checkbox
    class="add-category-dialog__content-form__checkbox"
    [(ngModel)]="clientVisible"
  >
    Visível para cliente
  </mat-checkbox>
  <mat-checkbox
    class="add-category-dialog__content-form__checkbox"
    [(ngModel)]="unscheduledEvent"
  >
    Cliente não pode agendar serviço
  </mat-checkbox>
</div>

<div mat-dialog-actions [align]="isTeenyMobile ? 'center' : 'end'">
  <button
    mat-raised-button
    (click)="submit()"
    class="add-category-dialog__actions"
    color="accent"
    [disabled]="formIsInvalid()"
  >
    {{ labelAction }}
  </button>
  <button
    mat-raised-button
    (click)="close()"
    class="add-category-dialog__actions"
  >
    Cancelar
  </button>
</div>
