import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ClientComponent } from './client/client.component';
import { CategoryComponent } from './category/category.component';
import { AvailabilityComponent } from './availability/availability.component';
import { AddAvailabilityComponent } from './availability/add-availability/add-availability.component';
import { EditAvailabilityComponent } from './availability/edit-availability/edit-availability.component';
import { CalendarComponent } from './calendar/calendar.component';
import { provideNgxMask } from 'ngx-mask';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInterceptor } from 'src/app/shared/services/interceptor/http-client-interceptor.service';
export const ROUTES_ADMIN_AREA: Routes = [
  {
    path: 'calendar',
    component: CalendarComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'client',
    component: ClientComponent,
  },
  {
    path: 'category',
    component: CategoryComponent,
  },
  {
    path: 'availability',
    component: AvailabilityComponent,
  },
  {
    path: 'availability/add',
    component: AddAvailabilityComponent,
  },
  {
    path: 'availability/edit',
    component: EditAvailabilityComponent,
  },
  {
    path: '',
    redirectTo: 'calendar',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES_ADMIN_AREA)],
  exports: [RouterModule],
  providers: [
    provideNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
  ],
})
export class AdminAreaRoutingModule {}
