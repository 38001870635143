import {
  Component,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDrawer } from '@angular/material/sidenav';
import { MAX_WIDTH_FIXED_SIDE_BAR } from '../../validators/breakpoints';
import { Router } from '@angular/router';
import {
  SessionDataUserType,
  SessionUserService,
} from '../../services/session-user/session-user.service';
import { TypeHeader, getHeadersAdmin, getHeadersClient } from './header.model';
import { Store } from '@ngrx/store';
import { UserTypeLogin } from 'src/app/pages/login/login.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatDrawer) sidenav!: MatDrawer;

  public sideBarHidden = false;
  public headers: TypeHeader[] = [];
  private storeSubscription: Subscription | null = null;
  private userType: UserTypeLogin | null = null;

  public clickToogle = () => {
    if (this.sidenav) this.sidenav.toggle();
  };

  constructor(
    private observer: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private sessionUserService: SessionUserService,
    private store: Store<{ userSession: SessionDataUserType }>
  ) {}

  ngOnDestroy(): void {
    if (this.storeSubscription) this.storeSubscription.unsubscribe();
  }

  private buildHeaders = (isDesktop: boolean) => {
    this.storeSubscription = this.store
      .select('userSession')
      .subscribe((data: any) => {
        if (data.value !== null) {
          this.userType = data.value.userType;
          if (data.value.userType === UserTypeLogin.ADMIN)
            this.headers = getHeadersAdmin(isDesktop);
          else this.headers = getHeadersClient(isDesktop);
        }
      });
  };

  ngAfterViewInit() {
    this.observer.observe([MAX_WIDTH_FIXED_SIDE_BAR]).subscribe((res) => {
      if (res.matches) {
        this.sideBarHidden = true;
        this.sidenav.mode = 'over';
        this.sidenav.close();
        this.buildHeaders(false);
      } else {
        this.sideBarHidden = false;
        this.sidenav.mode = 'side';
        this.sidenav.close();
        this.buildHeaders(true);
      }
    });

    this.cdRef.detectChanges();
  }

  public clickRedirect = (path: string) => {
    this.router.navigateByUrl(`/${path}`);
    this.sidenav.close();
  };

  public redirectInstagram = () => {
    window.open('https://www.instagram.com/rosianealmeidamicropigmentacao/');
    this.sidenav.close();
  };

  public redirectWpp = () => {
    window.open('https://api.whatsapp.com/send?1=pt_BR&phone=554792631425');
    this.sidenav.close();
  };

  public redirectFacebook = () => {
    window.open('https://www.facebook.com/espacorosianealmeida/?locale=pt_BR');
    this.sidenav.close();
  };

  public logout = () => {
    if (this.userType === UserTypeLogin.ADMIN) {
      this.sessionUserService.removeSessionUser();
      window.location.reload();
    } else {
      this.sessionUserService.removeSessionUser();
      this.router.navigateByUrl('/login');
    }
  };

  public menuIsSidebar = () => this.sideBarHidden;
}
