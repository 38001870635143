import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PeriodInputOutput } from 'src/app/pages/admin-area/availability/add-availability/add-availability.model';
import { ServiceCategory } from 'src/app/pages/admin-area/category/category.model';
import { Location } from 'src/app/pages/admin-area/client/client.model';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';
import { TimeService } from 'src/app/shared/utils/time-service';

export type DialogConfirmationData = {
  service: ServiceCategory | null;
  location: Location | null;
  date: Date;
  period: PeriodInputOutput;
  submit: () => void
};

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.sass'],
})
export class DialogConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmationData,
    private dateFormatService: DateFormatService
  ) {}

  getLocation = (location?: Location | null) =>
    `${location?.street}, ${location?.number} - ${location?.district}, ${location?.city} - ${location?.state}`;

  public castDurationForLabel = (duration?: number) =>
    TimeService.castSecondsInLabel(duration ?? 200);

  castDateInStringFormat = (date: Date) =>
    this.dateFormatService.format(date.toString(), 'L');

  public getInputOrOutputFormat = (startDate: string, endDate: string) =>
    `Entada: ${this.dateFormatService.format(
      this.dateFormatService.getDateByDateStringUTC(startDate),
      'LT'
    )} - Saída: ${this.dateFormatService.format(
      this.dateFormatService.getDateByDateStringUTC(endDate),
      'LT'
    )}.`;
}
