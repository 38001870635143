import { Injectable } from '@angular/core';

import * as moment from 'moment';
import 'moment/locale/pt-br';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  constructor() {
    moment.locale('pt-BR');
  }

  public getDateUTC = (date: Date) =>
    new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    );

  public format = (date: string | Date, format: string) => {
    if (!!date) return moment(date).format(format);

    return '';
  };

  public castDateForShortString = (date: Date): string => {
    if (!!date) return moment(date).format('L');

    return '';
  };

  public getDateByDateStringUTC = (dateStringUTC: string) => {
    const dateUTC = new Date(dateStringUTC.replace('[UTC]', ''));

    return new Date(moment.utc(dateUTC).valueOf());
  };
}
