<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>
<div
  class="container-availability"
  [ngClass]="{ 'container-availability__loading': isLoading }"
>
  <h1 class="container-availability__title">
    Disponibilidade de horários
    <button
      mat-fab
      class="container-client__title-action"
      color="accent"
      (click)="addAvailability()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </h1>

  <h3 class="container-availability__sub-title">
    Cadastre os horários que seus clientes irão agendar um serviço com você!
  </h3>

  <div class="container-availability__filters">
    <h3 class="container-availability__sub-title">Filtros</h3>
    <form [formGroup]="form" class="container-availability__filters-form">
      <mat-form-field class="container-availability__filters-form__location">
        <mat-label>Localização</mat-label>
        <mat-select formControlName="location" required>
          <mat-option
            *ngFor="let location of locations"
            [value]="location.value"
          >
            {{ location.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="fieldIsInvalid('location')" class="mat-error">{{
          getErrorGenericMessage("location")
        }}</mat-error>
      </mat-form-field>

      <mat-form-field class="container-availability__filters-form__period">
        <mat-label>Período</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            placeholder="Data inicial"
            formControlName="rangePeriodStart"
          />
          <input
            matEndDate
            placeholder="Data final"
            formControlName="rangePeriodEnd"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="rangePeriodIsInvalid()" class="mat-error">{{
          getErrorGenericMessage("rangePeriodStart") === ""
            ? getErrorGenericMessage("rangePeriodEnd")
            : getErrorGenericMessage("rangePeriodStart")
        }}</mat-error>
      </mat-form-field>
    </form>

    <div class="container-availability__filters-action">
      <button
        mat-raised-button
        class="container-availability__filters-action__submit"
        color="accent"
        [disabled]="formIsInvalid()"
        (click)="submit()"
      >
        Pesquisar
      </button>
    </div>
  </div>

  <div class="container-availability__cards" *ngIf="!!items">
    <mat-accordion
      class="container-availability__cards-accordion"
      *ngFor="let item of items"
    >
      <mat-expansion-panel class="container-availability__cards-accordion__panel">
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            getDateFormatAccordtion(item.date)
          }}</mat-panel-title>
        </mat-expansion-panel-header>

        <div class="container-availability__cards-accordion__content">
          <mat-card
            *ngFor="let input of item.inputOutput"
            class="container-availability__cards-accordion-item"
          >
            <mat-card-content>{{
              getInputOrOutputFormat(input.startDate, input.endDate)
            }}</mat-card-content>
          </mat-card>
        </div>

        <div class="container-availability__cards-accordion-group-actions">
          <button
            mat-raised-button
            color="accent"
            (click)="editAvailability(item.date, item.location)"
          >
            Editar
          </button>
          <button
            mat-raised-button
            color="accent"
            (click)="delete(item.date, item.location.id)"
          >
            Remover todos
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
