<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>

<div
  class="services-container"
  [ngClass]="{ 'services-container__loading': isLoading }"
>
  <div class="services-container__title">
    <img src="../../../../assets/services_and_prices.png" alt="" />
    <h1>Serviços e preços</h1>
  </div>

  <div
    class="services-container__categorie"
    *ngFor="let category of categories"
  >
    <h2>{{ category.description }}</h2>

    <div class="services-container__categorie-group">
      <div
        class="services-container__categorie-service"
        *ngFor="let service of category.services"
      >
        <img [src]="service.image" />
        <div class="services-container__categorie-service__information">
          <h3>{{ service.name }}</h3>
          <p>{{ service.description }}</p>
          <p>
            <strong>Forma de pagamento:</strong> {{ service.paymentFormat }}
          </p>
          <p>
            <strong>Duração:</strong>
            {{ castDurationForLabel(service.durationInSeconds) }}
          </p>
          <p style="text-align: right">
            {{ castValueInFormatter(service.valueMonetary) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
