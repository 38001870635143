import {
  Component,
  AfterViewInit,
  Inject,
} from '@angular/core';
import { EventByClient, Location, RowClientByAdmin } from '../client.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MAX_WIDTH_MEDIUM } from 'src/app/shared/validators/breakpoints';

type ClientEventData = {
  fullName: string;
  events: RowClientByAdmin['events'];
};

@Component({
  selector: 'app-client-event-dialog',
  templateUrl: './client-event-dialog.component.html',
  styleUrls: ['./client-event-dialog.component.sass'],
})
export class ClientEventComponentDialog implements AfterViewInit {
  public isTablet = false;

  constructor(
    public dialogRef: MatDialogRef<ClientEventComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ClientEventData,
    private dateFormatService: DateFormatService,
    private observer: BreakpointObserver
  ) {}

  ngAfterViewInit(): void {
    this.observer.observe([MAX_WIDTH_MEDIUM]).subscribe((res) => {
      this.isTablet = res.matches;
    });
  }

  getTitle = () =>
    this.data && this.data?.fullName
      ? `Horários marcados de ${this.data.fullName.split(' ')[0]}`
      : '';

  close = () => this.dialogRef.close();

  castDateInStringFormat = (date: Date) =>
    this.dateFormatService.format(date.toString(), 'LLL');

  getLocation = (location: Location) =>
    `${location.street}, ${location.number} - ${location.district}, ${location.city} - ${location.state}`;

  private eventCancled = (row: EventByClient) => row.canceled;

  private eventSuccess = (row: EventByClient) => row.endTime < new Date();

  getClassForStatus = (row: EventByClient): string => {
    if (this.eventCancled(row)) return 'event__dialog-panel__status-error';
    if (this.eventSuccess(row)) return 'event__dialog-panel__status-success';

    return 'event__dialog-panel__status-pending';
  };

  getTextForStatus = (row: EventByClient) => {
    if (this.eventCancled(row)) return 'Cancelado';
    if (this.eventSuccess(row)) return 'Concluído';

    return 'Pendente';
  };

  nameServiceIsLarge = (name: string) => name.length > 15;
}
