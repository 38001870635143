import { AbstractControl, ValidationErrors } from '@angular/forms';

export class DocumentValidator {
  static validate(control: AbstractControl): ValidationErrors | null {
    const documentIsFalse = { validator: false };

    let document = control.value;

    if (typeof document !== 'string') return documentIsFalse;

    document = document.replace(/[^\d]+/g, '');

    if (document.length !== 11 || !!document.match(/(\d)\1{10}/))
      return documentIsFalse;

    document = document.split('');

    const validator = document
      .filter(
        (digit: any, index: any, array: any) =>
          index >= array.length - 2 && digit
      )
      .map((el: any) => +el);

    const toValidate = (pop: any) =>
      document
        .filter(
          (digit: any, index: any, array: any) =>
            index < array.length - pop && digit
        )
        .map((el: any) => +el);

    const rest = (count: any, pop: any) =>
      ((toValidate(pop).reduce(
        (soma: any, el: any, i: any) => soma + el * (count - i),
        0
      ) *
        10) %
        11) %
      10;

    if (!!(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]))
      return documentIsFalse;

    return null;
  }
}
