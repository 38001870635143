import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { MAX_WIDTH_FIXED_SIDE_BAR } from 'src/app/shared/validators/breakpoints';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ClientEventComponentDialog } from './client-event-dialog/client-event-dialog.component';
import { RowClientByAdmin } from './client.model';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';
import { ClientRegisterDialogComponent } from './client-register-dialog/client-register-dialog.component';
import { ClientService } from './client.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.sass'],
})
export class ClientComponent implements OnInit, AfterViewInit {
  private indexPage = 0;
  private maxPage = 10;
  private widthRegisterDialog = '50%';
  private widthEventDialog = '70%';
  private heigthDialog = '';
  public isLoading: boolean = false;
  public menuIsSideBar: boolean = false;
  public columns: string[] = [
    'id',
    'fullName',
    'dateBirth',
    'cpf',
    'email',
    'cellphone',
    'confirmationRegistration',
    'disabled',
  ];
  public dataSource = new MatTableDataSource();

  constructor(
    private cdRef: ChangeDetectorRef,
    private snackbarService: SnackbarService,
    private observer: BreakpointObserver,
    public dialog: MatDialog,
    private dateFormatService: DateFormatService,
    private service: ClientService
  ) {}

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.observer.observe([MAX_WIDTH_FIXED_SIDE_BAR]).subscribe((res) => {
      this.menuIsSideBar = res.matches;
      if (res.matches) {
        this.widthEventDialog = '100%';
        this.widthRegisterDialog = '100%';
        this.heigthDialog = '100%';
      }
    });

    this.cdRef.detectChanges();
    this.dataSource.paginator = this.paginator;
  }

  private getAll = async () => {
    this.isLoading = true;

    await this.service.getAll(this.indexPage, this.maxPage).subscribe({
      next: (response) => {
        this.dataSource.data = response;
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.snackbarService.showMessageError();
        console.log(error);
      },
    });
  };

  async ngOnInit() {
    await this.getAll();
  }

  public modifyPage = (event: any) => {
    this.indexPage = event.pageIndex;
    this.maxPage = event.pageSize;
    this.getAll();
  };

  openDialogEvents(row: RowClientByAdmin): void {
    let options: any = {
      width: this.widthEventDialog,
    };

    if (this.heigthDialog !== '')
      options = {
        ...options,
        heigth: this.heigthDialog,
      };

    this.dialog.open(ClientEventComponentDialog, {
      data: { events: row.events, fullName: row.fullName },
      width: this.widthEventDialog,
    });
  }

  openDialogCreateOrEdit(row?: RowClientByAdmin): void {
    const functionsDialog = {
      showOrHideLoading: this.clientRegisterShowOrHideLoading,
      showSuccessMessage: this.clientRegisterShowMessageSuccess,
      showErrorMessage: this.clientRegisterShowMessageError,
    };

    let options: any = {
      width: this.widthRegisterDialog,
    };

    if (this.heigthDialog !== '')
      options = {
        ...options,
        heigth: this.heigthDialog,
      };

    if (!!row) {
      const { id, fullName, cpf, email, dateBirth, cellphone, disabled } = row;

      this.dialog.open(ClientRegisterDialogComponent, {
        data: {
          id,
          fullName,
          cpf,
          email,
          dateBirth,
          cellphone,
          isDisabled: disabled,
          ...functionsDialog,
        },
        ...options,
      });
    } else
      this.dialog.open(ClientRegisterDialogComponent, {
        width: this.widthRegisterDialog,
        data: {
          ...functionsDialog,
        },
      });
  }

  castDateInStringFormat = (date: Date) => {
    if (!!date) return this.dateFormatService.format(date.toString(), 'L');
    return '';
  };

  private clientRegisterShowOrHideLoading = (event: boolean) => {
    this.isLoading = event;
  };

  private clientRegisterShowMessageSuccess = (event: string) => {
    this.snackbarService.showMessageSuccess(event);
    this.getAll();
  };

  private clientRegisterShowMessageError = (event: string) => {
    this.snackbarService.showMessageError(event);
  };
}
