import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { DateFormatService } from './../../../shared/services/date-format/date-format.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CalendarOptions, EventSourceInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import { CalendarService } from './calendar.service';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '../client/client.model';
import { AddEventDialogComponent } from './add-event-dialog/add-event-dialog.component';
import { IEventCalendar } from './calendar.model';
import { MAX_WIDTH_MEDIUM } from 'src/app/shared/validators/breakpoints';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.sass'],
})
export class CalendarComponent implements OnInit, AfterViewInit {
  constructor(
    private calendarService: CalendarService,
    private snackbarService: SnackbarService,
    private dateFormatService: DateFormatService,
    private dialog: MatDialog,
    private observer: BreakpointObserver
  ) {}

  public isMediumTablet = false;
  private events: IEventCalendar[] = [];
  public form: FormGroup = new FormGroup({
    location: new FormControl([Validators.required]),
  });
  private startPeriodCalendar = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  private endPeriodCalendar = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );

  private initializeComplete = false;

  public locations: {
    name: string;
    value: Location;
  }[] = [];

  public eventsCalendar: EventSourceInput = [];
  public calendarOptions: CalendarOptions = {};

  public isLoading = false;

  ngAfterViewInit(): void {
    this.observer.observe([MAX_WIDTH_MEDIUM]).subscribe((res) => {
      this.isMediumTablet = res.matches;
    });
  }

  private getAllByLocationAndDates = (
    locationId: number,
    startDate: Date,
    endDate: Date
  ) => {
    try {
      this.startPeriodCalendar = startDate;
      this.endPeriodCalendar = endDate;
      this.isLoading = true;
      this.calendarService
        .getAll(locationId, this.startPeriodCalendar, this.endPeriodCalendar)
        .subscribe({
          next: (events) => {
            this.calendarOptions.events = [];
            this.events = events;
            for (const event of events) {
              this.calendarOptions.events.push({
                title: event.service.name,
                start: this.dateFormatService.getDateByDateStringUTC(
                  event.startTime
                ),
                end: this.dateFormatService.getDateByDateStringUTC(
                  event.endTime
                ),
                date: this.startPeriodCalendar,
                id: event.id.toString(),
              });
            }

            this.isLoading = false;
            this.initializeComplete = true;
          },
          error: (error) => {
            console.log(error);
            this.snackbarService.showMessageError();
            this.isLoading = false;
          },
        });
    } catch (error) {
      this.initializeComplete = true;
      this.isLoading = false;
      this.snackbarService.showMessageError();
    }
  };

  private handleModifyDates = (data: any) => {
    if (this.initializeComplete) {
      const location = this.form.get('location')?.value;
      if (!!location && location !== '' && !!data) {
        if (!!data?.start && !!data?.end)
          if (
            data.start < this.startPeriodCalendar ||
            data.end > this.endPeriodCalendar
          )
            this.getAllByLocationAndDates(
              this.form.get('location')?.value?.id,
              data.start,
              data.end
            );
      }
    }
  };

  private getAllLocations = () => {
    this.isLoading = true;
    this.calendarService.getAllLocations().subscribe({
      next: (locations: Location[]) => {
        if (!!locations && locations?.length > 0) {
          locations.forEach((location: Location) => {
            this.locations.push({
              name: `${location.street}, ${location.number} - ${location.district}, ${location.city} - ${location.state}`,
              value: location,
            });
          });

          this.form.setValue({
            location: this.locations[0].value,
          });

          this.getAllByLocationAndDates(
            this.locations[0].value.id,
            this.startPeriodCalendar,
            this.endPeriodCalendar
          );
        } else this.isLoading = false;
      },
      error: (error) => {
        this.snackbarService.showMessageError();
        console.log(error);
        this.isLoading = false;
      },
      complete: () => {
        if (this.locations.length === 0) {
          this.snackbarService.showMessageSuccess(
            'Você não possui localizações cadastradas!'
          );
          this.isLoading = false;
        }
      },
    });
  };

  private clickEdit = (data: any) => {
    const idEvent = data?.event?._def?.publicId;
    if (!!idEvent) {
      const info = this.events.findIndex(
        (event) => event.id.toString() == idEvent
      );
      this.dialog.open(AddEventDialogComponent, {
        ...this.getWidthAndHeight(),
        data: {
          location: this.form.get('location')?.value as Location,
          showErrorMessage: this.showErrorMessage.bind(this),
          showSuccessMessage: this.showSuccessMessage.bind(this),
          info: { ...this.events[info], client: this.events[info].client },
        },
      });
    }
  };

  private initCalendar = () => {
    this.calendarOptions = {
      locale: ptBrLocale,
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      events: this.eventsCalendar,
      initialView: 'dayGridMonth',
      weekends: true,
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      eventClick: this.clickEdit.bind(this),
      datesSet: this.handleModifyDates.bind(this),
      /* you can update a remote database when these fire:
      eventAdd:
      eventChange:
      eventRemove:
      */
    };
    this.getAllLocations();
  };

  private showSuccessMessage = (message: string) => {
    this.snackbarService.showMessageSuccess(message);

    this.getAllByLocationAndDates(
      this.form.get('location')?.value?.id,
      this.startPeriodCalendar,
      this.endPeriodCalendar
    );
  };

  private showErrorMessage = (message: string) => {
    this.snackbarService.showMessageError(message);
  };

  private showOrHideLoading = (show: boolean) => (this.isLoading = show);

  ngOnInit() {
    this.initCalendar();
  }

  public formIsInvalid = () => this.form.invalid;

  private getWidthAndHeight = () => {
    return {
      width: this.isMediumTablet ? '90%' : '60%',
    };
  };

  public showAddEventDialog = () => {
    this.dialog.open(AddEventDialogComponent, {
      ...this.getWidthAndHeight(),
      data: {
        location: this.form.get('location')?.value as Location,
        showErrorMessage: this.showErrorMessage.bind(this),
        showOrHideLoading: this.showOrHideLoading.bind(this),
        showSuccessMessage: this.showSuccessMessage.bind(this),
      },
    });
  };
}
