export class TimeService {
  public static castHoursInSeconds = (hoursAndMinutes: string) => {
    const hoursAndMinutesSplit = hoursAndMinutes.split(':');

    return +hoursAndMinutesSplit[0] * 60 * 60 + +hoursAndMinutesSplit[1] * 60;
  };

  public static castSecondsInHours = (seconds: any) => {
    let hours: any = Math.floor(seconds / 3600);
    if (hours > 0 && hours < 10) {
      hours = `0${hours}`;
    }
    if (hours >= 1) seconds = seconds - hours * 3600;
    else hours = '00';

    let minutes: any = Math.floor(seconds / 60);

    if (minutes >= 1) seconds = seconds - minutes * 60;
    else minutes = '00';

    if (seconds < 1) seconds = '00';

    if (minutes.toString().length == 1) minutes = `0${minutes}`;

    return `${hours}:${minutes}`;
  };

  public static castSecondsInLabel = (seconds: number): string => {
    if (seconds < 3600) {
      const hours = Math.floor(seconds / 60);
      var seconds = seconds % 60;
      const minutesLabel = hours > 1 ? 'minutos' : 'minuto';

      return `${hours} ${minutesLabel}`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = seconds % 3600;
      var seconds = Math.floor(minutes / 60);
      const c = seconds % 60;
      const minutesLabel = seconds > 1 ? 'minutos' : 'minuto';
      const hoursLabel = c > 1 ? 'horas' : 'hora';

      if (seconds == 0) return `${hours} ${hoursLabel}`;
      else return `${hours} ${hoursLabel} e ${seconds} ${minutesLabel}`;
    }
  };
}
