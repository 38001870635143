import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  SessionDataUserType,
  SessionUserService,
} from './shared/services/session-user/session-user.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { insert, reset } from './store/actions/user-session.action';
import { UserTypeLogin } from './pages/login/login.model';
import { ROUTES_ADMIN_AREA } from './pages/admin-area/admin-area-routing.module';
import { ROUTES_USER } from './pages/user/user-routing.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnDestroy, OnInit {
  public screenBuilded = false;
  public userIsLogged: boolean = false;
  public userSubscription: Subscription | null = null;

  constructor(
    private sessionUserService: SessionUserService,
    private router: Router,
    private store: Store<{ userSession: { value: SessionDataUserType } }>
  ) {}

  ngOnInit(): void {
    this.dispatchUserSession();
    this.handlerUserSession();
  }

  private dispatchUserSession = () => {
    const session = this.sessionUserService.getSessionDataInBrowser();
    if (session !== null) this.store.dispatch(insert(session));
    else this.store.dispatch(reset());
  };

  private pathIsAdminLink = (path: string): boolean => {
    let isValid = false;
    ROUTES_ADMIN_AREA.forEach((route) => {
      const routePath = `/admin-area/${route.path}`;
      if (routePath.includes(path)) {
        isValid = true;
        return;
      }
    });

    return isValid;
  };

  private pathIsUserLink = (path: string): boolean => {
    let isValid = false;
    ROUTES_USER.forEach((route) => {
      const routePath = `/user/${route.path}`;
      if (routePath === path) {
        isValid = true;
        return;
      }
    });

    return isValid;
  };

  private handlerUserSession = () => {
    this.userSubscription = this.store
      .select('userSession')
      .subscribe((userSession) => {
        this.userIsLogged = !!userSession.value && userSession.value !== null;

        const url = window.location.pathname;

        if (this.userIsLogged) {
          if (userSession.value.userType === UserTypeLogin.ADMIN) {
            if (url === '/' || !this.pathIsAdminLink(url))
              this.router.navigateByUrl('/admin-area/calendar');
          } else if (url === '/' || !this.pathIsUserLink(url))
            this.router.navigateByUrl('/user/home');

          this.screenBuilded = true;
        } else if (url.includes('admin-area') || url.includes('user')) {
          this.router.navigateByUrl('/login');
          this.screenBuilded = true;
        } else this.screenBuilded = true;
      });
  };

  ngOnDestroy(): void {
    if (this.userSubscription) this.userSubscription.unsubscribe();
  }
}
