import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  FormArray,
} from '@angular/forms';
import { AddAvailabilityService } from './add-availability.service';
import { Location } from '../../client/client.model';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { Router } from '@angular/router';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';

@Component({
  selector: 'app-add-availability',
  templateUrl: './add-availability.component.html',
  styleUrls: ['./add-availability.component.sass'],
})
export class AddAvailabilityComponent implements OnInit {
  public isLoading = false;
  public inputsOutputs = new FormArray<any>([]);
  public disabledPeriod = true;

  public form: FormGroup = new FormGroup({
    location: new FormControl([Validators.required]),
    rangePeriodStart: new FormControl([Validators.required]),
    rangePeriodEnd: new FormControl([Validators.required]),
  });

  public locations: {
    name: string;
    value: Location;
  }[] = [];

  constructor(
    private service: AddAvailabilityService,
    private snackbarService: SnackbarService,
    private router: Router,
    private dateFormatService: DateFormatService
  ) {}

  public createInputAndOutput = () => {
    this.inputsOutputs.push(new FormControl('', [Validators.required]));
    this.inputsOutputs.push(new FormControl('', [Validators.required]));
  };

  ngOnInit() {
    this.buildLocations();
    this.createInputAndOutput();
  }

  private buildLocations = () => {
    this.isLoading = true;
    this.service.getAllLocations().subscribe({
      next: (locations: Location[]) => {
        locations.forEach((location) => {
          this.locations.push({
            name: `${location.street}, ${location.number} - ${location.district}, ${location.city} - ${location.state}`,
            value: location,
          });
        });
      },
      complete: () => (this.isLoading = false),
      error: (error: any) => {
        this.snackbarService.showMessageError();
        console.log(error);
      },
    });
  };

  private getControl = (field: string): AbstractControl | null =>
    this.form.get(field);

  public rangePeriodIsInvalid = () =>
    this.fieldIsInvalid('rangePeriodStart') &&
    this.fieldIsInvalid('rangePeriodEnd');

  public fieldIsInvalid = (field: string): boolean => {
    const fieldControl = this.getControl(field);

    if (fieldControl) return fieldControl.invalid;
    return false;
  };

  public getErrorGenericMessage = (field: string): string => {
    const fieldControl = this.getControl(field);

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';

      if (fieldControl.invalid) return 'Campo inválido!';
    }

    return '';
  };

  public fieldInputOrOutputInvalid = (control: AbstractControl) =>
    control.invalid;

  public formIsInvalid = () => {
    return this.form.invalid || this.inputsOutputs.invalid;
  };

  public submit = () => {
    this.isLoading = true;

    const rangePeriodStart = this.dateFormatService.getDateUTC(
      new Date(this.getControl('rangePeriodStart')?.value as Date)
    );

    const rangePeriodEnd = this.dateFormatService.getDateUTC(
      new Date(this.getControl('rangePeriodEnd')?.value as Date)
    );

    this.service
      .insertPeriod({
        location: this.getControl('location')?.value as Location,
        rangePeriodStart,
        rangePeriodEnd,
        inputsOrOutputs: this.inputsOutputs.getRawValue(),
      })
      .subscribe({
        next: () => {
          this.snackbarService.showMessageSuccess(
            'Período de horário criado com sucesso!'
          );
          this.isLoading = false;
          this.back();
        },
        error: (error: any) => {
          this.snackbarService.showMessageError();
          this.isLoading = false;
          console.log(error);
        },
      });
  };

  public deleteInputAndOutput = (index: number) => {
    this.inputsOutputs.controls.splice(index, 2);
  };

  public onChangePeriodDates = () => {
    if (!this.disabledPeriod && !this.rangePeriodIsInvalid()) {
      const startDateControl = this.getControl('rangePeriodStart')?.value;
      const endDateControl = this.getControl('rangePeriodEnd')?.value;

      if (
        !!startDateControl &&
        !!endDateControl &&
        !isNaN(startDateControl) &&
        !isNaN(endDateControl)
      ) {
        this.disabledPeriod = true;
        this.isLoading = true;

        const startDate = this.dateFormatService.getDateUTC(
          new Date(startDateControl)
        );
        const endDate = this.dateFormatService.getDateUTC(
          new Date(endDateControl)
        );

        this.service
          .periodAllowedInsertNewavailability({
            location: this.getControl('location')?.value as Location,
            initialDatePeriod: startDate as Date,
            endDatePeriod: endDate as Date,
          })
          .subscribe({
            error: (error: any) => {
              if (error.status === 403)
                this.snackbarService.showMessageError(
                  'Há horários disponíveis cadastrados nessas datas. Edite os horários para que possam modificar!',
                  10
                );
              else this.snackbarService.showMessageError();

              this.isLoading = false;
              this.disabledPeriod = false;
              this.form.get('rangePeriodStart')?.setValue('');
              this.form.get('rangePeriodEnd')?.setValue('');
              this.form
                .get('rangePeriodStart')
                ?.setErrors([Validators.required]);
              this.form.get('rangePeriodEnd')?.setErrors([Validators.required]);
            },

            complete: () => {
              this.isLoading = false;
              this.disabledPeriod = false;
            },
          });
      }
    }
  };

  public onChangeLocationForEnabledPeriod = () => {
    const locationField = this.getControl('location');
    this.disabledPeriod =
      locationField?.value === null || locationField?.value === undefined;
    if (!this.disabledPeriod) this.onChangePeriodDates();
  };

  public back = () => {
    this.router.navigateByUrl('/admin-area/availability');
  };
}
