import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: 'register',
    loadComponent: () =>
      import('../app/pages/register/register.component').then(
        (r) => r.RegisterComponent
      ),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'confirmation-contact/:id',
    loadComponent: () =>
      import(
        './pages/confirmation-contact/confirmation-contact.component'
      ).then((m) => m.ConfirmationContactComponent),
  },
  {
    path: 'admin-area',
    loadChildren: () =>
      import('./pages/admin-area/admin-area.module').then(
        (m) => m.AdminAreaModule
      ),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./pages/user/user.module').then((m) => m.UserModule),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
