import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  StatusUser,
  UserAuthenticationRequest,
  UserAuthenticationResponse,
  UserTypeLogin,
} from './login.model';
import { SessionUserService } from 'src/app/shared/services/session-user/session-user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private httpClient: HttpClient,
    private sessionUserService: SessionUserService,
    private router: Router
  ) {}

  private setSessionUser = ({
    token,
    newUserID,
    userType,
  }: UserAuthenticationResponse) => {
    this.sessionUserService.setSessionTokenInBrowser(token!!);
    this.sessionUserService.setSessionDataInBrowser({
      userId: newUserID,
      userType: userType,
    });
  };

  public userAuthentication = (
    user: UserAuthenticationRequest
  ): Observable<UserAuthenticationResponse> => {
    return this.httpClient
      .post<UserAuthenticationResponse>(
        'user/authentication',
        JSON.stringify(user)
      )
      .pipe(
        map((response: UserAuthenticationResponse) => {
          if (
            response.status === StatusUser.VALID_CREDENTIALS &&
            response.token
          ) {
            const url =
              response.userType === UserTypeLogin.ADMIN
                ? '/admin-area/calendar'
                : '/user/home';

            this.router
              .navigateByUrl(url)
              .then(() => this.setSessionUser(response));
          }

          return response;
        })
      );
  };
}
