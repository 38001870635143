<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>

<div
  class="login-container"
  [ngClass]="{ 'login-container__loading': isLoading }"
>
  <div class="content">
    <img
      src="../../../assets/complete-logo.png"
      alt="Logo da Rosiane Almeida"
    />

    <form [formGroup]="form" (keydown.enter)="submit()">
      <mat-form-field>
        <mat-icon matTextPrefix>remember_me</mat-icon>
        <mat-label>CPF ou e-mail</mat-label>
        <input matInput formControlName="documentOrEmail" />
        <mat-error
          *ngIf="fieldIsInvalid('documentOrEmail')"
          class="mat-error"
          >{{ getErrorGenericMessage("documentOrEmail") }}</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-icon matTextPrefix>lock</mat-icon>
        <mat-label>Senha</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
        />
        <button
          mat-icon-button
          matSuffix
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
          type="button"
          (click)="hide = !hide"
        >
          <mat-icon
            [fontIcon]="hide ? 'visibility_off' : 'visibility'"
          ></mat-icon>
        </button>
        <mat-error
          *ngIf="getErrorGenericMessage('password') !== ''"
          class="mat-error"
          >{{ getErrorGenericMessage("password") }}</mat-error
        >
      </mat-form-field>
      <button
        mat-raised-button
        class="submit"
        (click)="$event.preventDefault();submit()"
        type="button"
        [disabled]="formIsInvalid()"
      >
        Entrar
      </button>
    </form>

    <a class="link-register" href="/register">Cadastrar-se</a>

    <p>Faça seu cadastro e contrate os serviços de Rosiane Almeida!</p>
  </div>

  <div class="image-principal">
    <img src="../../../assets/login.png" alt="Estética da sobrancelha" />
  </div>
</div>
