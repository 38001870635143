import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryService } from '../../admin-area/category/category.service';
import { Category } from '../../admin-area/category/category.model';

const URL = 'category-service';

@Injectable({
  providedIn: 'root',
})
export class Services {
  constructor(private httpClient: HttpClient) {}

  public get = (): Observable<Category[]> =>
    this.httpClient.get<Category[]>(URL).pipe();
}
