<mat-spinner class="loading" color="warn" *ngIf="isLoading()"></mat-spinner>

<div
  class="scheduling-container"
  [ngClass]="{ 'services-container__loading': isLoading() }"
>
  <h1 class="scheduling-container__title">Agendamento de horário</h1>

  <div class="scheduling-container__box">
    <div class="scheduling-container__box-form">
      <h3 class="scheduling-container__sub-title">
        Preencha as informações abaixo para visualizar os horários de
        disponíves:
      </h3>
      <mat-form-field>
        <mat-label>Serviços</mat-label>
        <mat-select
          [formControl]="categoriesFormControl"
          (ngModelChange)="changeServiceOrDate()"
        >
          <mat-optgroup
            [label]="categorie.description"
            *ngFor="let categorie of categories"
          >
            <mat-option
              *ngFor="let service of categorie.services"
              [value]="service"
              >{{ service.name }}</mat-option
            >
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Locais de atendimento</mat-label>
        <mat-select
          [formControl]="locationFormControl"
          (ngModelChange)="changeLocation()"
          disableRipple
        >
          <mat-option [value]="location" *ngFor="let location of locations">{{
            getLabelLocation(location)
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Data para agendamento</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [matDatepickerFilter]="filterDates"
          [formControl]="dateFormControl"
          (ngModelChange)="changeServiceOrDate()"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #picker
          startView="year"
          [startAt]="startDate"
        ></mat-datepicker>
        <mat-error *ngIf="dateFormInvalid()" class="mat-error">Está data não está disponível na agenda!</mat-error>
      </mat-form-field>
    </div>

    <mat-divider
      *ngIf="schedulingTimes !== null"
      [vertical]="dividirVertical"
    ></mat-divider>

    <div
      class="scheduling-container__box-schedules"
      *ngIf="schedulingTimes !== null"
    >
      <div
        class="scheduling-container__box-schedules__with-hours"
        *ngIf="schedulingTimes.period.length > 0"
      >
        <h2>Horários disponíveis com Rosi:</h2>
        <div
          class="scheduling-container__box-schedules-hour"
          *ngFor="let period of schedulingTimes.period"
        >
          <div class="scheduling-container__box-schedules-hour-data">
            <mat-icon>date_range</mat-icon>
            <p>
              {{
                getInputOrOutputFormat(
                  period.input.toString(),
                  period.output.toString()
                )
              }}
            </p>
          </div>

          <button
            mat-raised-button
            class="scheduling-container__box-schedules-hour-submit"
            color="accent"
            (click)="openDialog(period)"
          >
            Agendar
          </button>
        </div>
      </div>

      <h2 *ngIf="schedulingTimes.period.length === 0">
        Rosiane Almeida não possui horários disponíveis no dia
        {{ getShortDateFormat(dateSelectedLabel) }}!
      </h2>
    </div>
  </div>
</div>
