<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>

<div
  class="register-container"
  [ngClass]="{ 'register-container__loading': isLoading }"
>
  <div class="image-principal">
    <img
      src="../../../assets/register-account.png"
      alt="Estética da sobrancelha"
    />
  </div>
  <div class="content">
    <img
      src="../../../assets/complete-logo.png"
      alt="Logo da Rosiane Almeida"
    />
    <form [formGroup]="form" (keydown.enter)="submit()">
      <mat-form-field>
        <mat-icon matTextPrefix>remember_me</mat-icon>
        <mat-label>Nome completo</mat-label>
        <input matInput formControlName="fullName" />
        <mat-error *ngIf="fieldIsInvalid('fullName')" class="mat-error">{{
          getErrorFullName()
        }}</mat-error>
      </mat-form-field>

      <mat-form-field class="date-birth">
        <mat-icon matTextPrefix>cake</mat-icon>
        <mat-label>Data de nascimento</mat-label>
        <input matInput [matDatepicker]="dp" formControlName="dateBirth" />
        <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
        <mat-hint>Promoções no mês de aniversário!</mat-hint>
        <mat-error *ngIf="fieldIsInvalid('dateBirth')" class="mat-error">{{
          getErrorGenericMessage("dateBirth")
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-icon matTextPrefix>badge</mat-icon>
        <mat-label>CPF</mat-label>
        <input
          matInput
          placeholder="Ex: 000.000.000-00"
          formControlName="document"
          mask="000.000.000-00"
          type="text"
        />
        <mat-error *ngIf="fieldIsInvalid('document')" class="mat-error">{{
          getErrorGenericMessage("document")
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-icon matTextPrefix>mail</mat-icon>
        <mat-label>E-mail</mat-label>
        <input
          matInput
          placeholder="Ex: rosi@exemplo.com"
          formControlName="email"
          required
        />
        <mat-error *ngIf="fieldIsInvalid('email')" class="mat-error">{{
          getErrorGenericMessage("email")
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-icon matTextPrefix>smartphone</mat-icon>
        <mat-label>Telefone</mat-label>
        <input
          matInput
          placeholder="Ex: (99) 99999-9999"
          formControlName="cellphone"
          mask="(00) 00000-0000"
        />
        <mat-error *ngIf="fieldIsInvalid('cellphone')" class="mat-error">{{
          getErrorGenericMessage("cellphone")
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-icon matTextPrefix>lock</mat-icon>
        <mat-label>Senha</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
        />
        <button
          mat-icon-button
          matSuffix
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
          type="button"
          (click)="hide = !hide"
        >
          <mat-icon
            [fontIcon]="hide ? 'visibility_off' : 'visibility'"
          ></mat-icon>
        </button>
        <mat-error
          *ngIf="fieldIsInvalid('password')"
          class="mat-error-password"
          >{{ getErrorPassword() }}</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-icon matTextPrefix>lock</mat-icon>
        <mat-label>Confirmação de senha</mat-label>
        <input
          matInput
          [type]="hideConfirmationPassword ? 'password' : 'text'"
          formControlName="confirmPassword"
        />
        <button
          mat-icon-button
          matSuffix
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideConfirmationPassword"
          type="button"
          (click)="hideConfirmationPassword = !hideConfirmationPassword"
        >
          <mat-icon
            [fontIcon]="hideConfirmationPassword ? 'visibility_off' : 'visibility'"
          ></mat-icon>
        </button>
        <mat-error *ngIf="getErrorConfirmPassword() !== ''" class="mat-error">{{
          getErrorConfirmPassword()
        }}</mat-error>
      </mat-form-field>
      <button
        mat-raised-button
        class="submit"
        (click)="$event.preventDefault();submit()"
        [disabled]="formIsInvalid()"
        type="button"
      >
        Cadastrar
      </button>
    </form>
  </div>
</div>
