<div class="add-event-dialog-container">
  <mat-spinner class="loading" color="warn" *ngIf="pageIsLoading()"></mat-spinner>
  <h1 mat-dialog-title class="add-event-dialog__title">{{ title }}</h1>
  <div
    mat-dialog-content
    class="add-event-dialog__content"
    [ngClass]="{ 'add-event-dialog__loading': pageIsLoading() }"
  >
    <form [formGroup]="form">
      <div class="add-event-dialog__content-form">
        <mat-form-field class="add-event-dialog__content-form__client">
          <mat-label>Cliente</mat-label>
          <mat-select formControlName="clients" required>
            <mat-option *ngFor="let client of clients" [value]="client.value">
              {{ client.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="fieldIsInvalid('clients')" class="mat-error">{{
            getErrorGenericMessage("clients")
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="add-event-dialog__content-form">
        <mat-form-field class="add-event-dialog__content-form__category">
          <mat-label>Categoria</mat-label>
          <mat-select
            (ngModelChange)="onChangeCategory()"
            formControlName="category"
            required
          >
            <mat-option
              *ngFor="let category of categories"
              [value]="category.value"
            >
              {{ category.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="fieldIsInvalid('category')" class="mat-error">{{
            getErrorGenericMessage("category")
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="add-event-dialog__content-form__service">
          <mat-label>Serviço</mat-label>
          <mat-select
            formControlName="service"
            (ngModelChange)="onChangeService()"
            required
          >
            <mat-option
              *ngFor="let service of services"
              [value]="service.value"
            >
              {{ service.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="fieldIsInvalid('service')" class="mat-error">{{
            getErrorGenericMessage("service")
          }}</mat-error>
        </mat-form-field>
      </div>

      <div class="add-event-dialog__content-form">
        <mat-form-field class="add-event-dialog__content-form__start-date">
          <mat-label>Data e horário do evento</mat-label>
          <input
            matInput
            formControlName="startDate"
            (change)="onChangeStartDate()"
            type="datetime-local"
            placeholder="start date"
          />
          <mat-error *ngIf="fieldIsInvalid('startDate')" class="mat-error">{{
            getErrorGenericMessage("startDate")
          }}</mat-error>
        </mat-form-field>

        <p
          class="add-event-dialog__content-form__end-date"
          *ngIf="hourEndDate !== ''"
        >
          Previsão para finalizar ás {{ hourEndDate }}
        </p>
      </div>
    </form>
  </div>

  <div mat-dialog-actions [align]="isMediumTablet ? 'center' : 'end'">
    <button
      *ngIf="isEdit"
      mat-raised-button
      (click)="cancelEvent()"
      class="add-event-dialog__actions"
      color="primary"
      [disabled]="formIsInvalid()"
    >
      Cancelar evento
    </button>
    <button
      mat-raised-button
      (click)="submit()"
      class="add-event-dialog__actions"
      color="accent"
      [disabled]="formIsInvalid()"
    >
      {{ labelAction }}
    </button>
    <button
      mat-raised-button
      (click)="close()"
      class="add-event-dialog__actions"
    >
      Fechar
    </button>
  </div>
</div>
