import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

const snackBarConfig: MatSnackBarConfig = {
  horizontalPosition: 'center',
  verticalPosition: 'bottom',
  duration: 3 * 1000,
  panelClass: 'snackbar-message',
};

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  showMessageSuccess = (message: string, durationSeconds?: number): void => {
    const config = snackBarConfig;
    if (!!durationSeconds) config.duration = durationSeconds * 1000;

    this.snackBar.open(message, '✔', snackBarConfig);
  };

  dismiss = () => {
    this.snackBar.dismiss();
  };

  showMessageError = (message?: string, durationSeconds?: number): void => {
    const config = snackBarConfig;
    if (!!durationSeconds) config.duration = durationSeconds * 1000;

    if (!!message && message !== '')
      this.snackBar.open(message, '', snackBarConfig);
    else
      this.snackBar.open(
        'Falha inesperada! Contate o administrador do sistema!',
        '',
        snackBarConfig
      );
  };
}
