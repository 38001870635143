<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>

<div
  class="edit-container-availability"
  [ngClass]="{ 'edit-container-availability__loading': isLoading }"
>
  <h1 class="edit-container-availability__title">
    {{ title }}
  </h1>

  <form [formGroup]="form" class="edit-container-availability__form">
    <div class="edit-container-availability__form-box">
      <div class="edit-container-availability__form-box-group">
        <h2 class="edit-container-availability__form-box-subtitle">Localização</h2>
        <mat-form-field>
          <mat-select formControlName="location">
            <mat-option
              *ngFor="let location of locations"
              [value]="location.value"
            >
              {{ location.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="fieldIsInvalid('location')" class="mat-error">{{
            getErrorGenericMessage("location")
          }}</mat-error>
        </mat-form-field>
      </div>

      <div>
        <h2 class="edit-container-availability__form-box-subtitle">
          Entradas e saídas
        </h2>
        <ng-template
          ngFor
          let-element
          [ngForOf]="inputsOutputs.controls"
          let-i="index"
          style="width: 100%"
        >
          <div
            class="edit-container-availability__form-box-input-output"
            *ngIf="i === inputsOutputs.controls.length - 2"
          >
            <mat-form-field>
              <mat-label>Entrada</mat-label>
              <input
                matInput
                type="time"
                [formControl]="$any(inputsOutputs.controls[i])"
              />
              <mat-error
                *ngIf="fieldInputOrOutputInvalid(inputsOutputs.controls[i])"
                class="mat-error"
                >{{ "Preencha este campo!" }}</mat-error
              >
            </mat-form-field>
            <mat-form-field>
              <mat-label>Saída</mat-label>
              <input
                matInput
                type="time"
                [formControl]="$any(inputsOutputs.controls[i + 1])"
              />
              <mat-error
                *ngIf="fieldInputOrOutputInvalid(inputsOutputs.controls[i + 1])"
                class="mat-error"
                >{{ "Preencha este campo!" }}</mat-error
              >
            </mat-form-field>
            <button
              mat-fab
              class="container-client__title-action"
              color="accent"
              (click)="createInputAndOutput()"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>

          <div
            class="edit-container-availability__form-box-input-output"
            *ngIf="i !== inputsOutputs.controls.length - 2 && i % 2 === 0"
          >
            <mat-form-field>
              <mat-label>Entrada</mat-label>
              <input
                matInput
                type="time"
                [formControl]="$any(inputsOutputs.controls[i])"
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Saída</mat-label>
              <input
                matInput
                type="time"
                [formControl]="$any(inputsOutputs.controls[i + 1])"
              />
            </mat-form-field>
            <button
              mat-fab
              class="container-client__title-action"
              color="accent"
              (click)="deleteInputAndOutput(i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="edit-container-availability__actions">
      <button mat-raised-button class="submit" (click)="back()">Voltar</button>
      <button
        mat-raised-button
        class="submit"
        color="accent"
        (click)="submit()"
        [disabled]="formIsInvalid()"
      >
        Editar
      </button>
    </div>
  </form>
</div>
