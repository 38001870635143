<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>

<div
  class="container-client"
  [ngClass]="{
  'container-client__desktop': !menuIsSideBar,
  'container-client__menu_dinamic': menuIsSideBar,
  'container-client__loading': isLoading,
}"
>
  <h1 class="container-client__title">
    Clientes
    <button
      mat-fab
      class="container-client__title-action"
      color="accent"
      (click)="openDialogCreateOrEdit()"
    >
      <mat-icon>person_add</mat-icon>
    </button>
  </h1>

  <div class="container-client__table mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="box-shadow">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Nome completo</th>
        <td mat-cell *matCellDef="let row">{{ row.fullName }}</td>
      </ng-container>

      <ng-container matColumnDef="dateBirth">
        <th mat-header-cell *matHeaderCellDef>Data de nascimento</th>
        <td mat-cell *matCellDef="let row">
          {{ castDateInStringFormat(row.dateBirth) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let row">{{ row.cpf }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>E-mail</th>
        <td mat-cell *matCellDef="let row">{{ row.email }}</td>
      </ng-container>

      <ng-container matColumnDef="cellphone">
        <th mat-header-cell *matHeaderCellDef>Telefone</th>
        <td mat-cell *matCellDef="let row">{{ row.cellphone }}</td>
      </ng-container>

      <ng-container matColumnDef="confirmationRegistration">
        <th mat-header-cell *matHeaderCellDef>Contanto confirmado</th>
        <td mat-cell *matCellDef="let row">
          {{ row.confirmationRegistration ? "Sim" : "Não" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="disabled">
        <th mat-header-cell *matHeaderCellDef>Acesso desativado</th>
        <td mat-cell *matCellDef="let row">
          {{ row.disabled ? "Sim" : "Não" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let row" class="table-actions">
          <button
            mat-mini-fab
            color="accent"
            (click)="openDialogCreateOrEdit(row)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-mini-fab color="accent" (click)="openDialogEvents(row)">
            <mat-icon>menu</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>

      <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[10, 20, 30]"
      showFirstLastButtons
      aria-label="Selecione a página"
      (page)="modifyPage($event)"
    >
    </mat-paginator>
  </div>
</div>
