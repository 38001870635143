import { SnackbarService } from './../../../shared/services/snackbar/snackbar.service';
import { TimeService } from 'src/app/shared/utils/time-service';
import { Category } from '../../admin-area/category/category.model';
import { Services } from './services.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.sass'],
})
export class ServicesComponent implements OnInit {
  public categories: Category[] = [];
  public isLoading = false;
  public formatter: any;
  constructor(
    private services: Services,
    private snackbarService: SnackbarService
  ) {
    this.formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.services.get().subscribe({
      next: (data) => {
        data.forEach((categorie) => {
          if (categorie.clientVisible) this.categories.push(categorie);
        });
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.snackbarService.showMessageError();
      },
    });
  }

  public castDurationForLabel = (duration: number) =>
    TimeService.castSecondsInLabel(duration);

  public castValueInFormatter = (value: number) => {
    return this.formatter.format(value);
  };
}
