import 'moment/locale/pt';

import { NgModule } from '@angular/core';

// Modules.
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { AppComponent } from './app.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';

// Components.
import { RegisterComponent } from './pages/register/register.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpClientInterceptor } from './shared/services/interceptor/http-client-interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  RouterLinkWithHref,
  RouterOutlet,
  provideRouter,
} from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { MatPaginatorProvider } from './shared/providers/mat-paginator-provider';
import { StoreModule } from '@ngrx/store';
import { userSessionReducer } from './store/reducer/user-session.reducer';

import { APP_ROUTES } from './routes';

import { getFormatsDatePickerProvider } from './shared/providers/date-picker-format-provider';

import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      if (confirm('New version available. Load New Version?')) {
        window.location.reload();
      }
    }
  }
}

@NgModule({
  declarations: [AppComponent, HeaderComponent],
  imports: [
    LoginComponent,
    BrowserModule,
    RegisterComponent,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterOutlet,
    NgIf,
    NgFor,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatDividerModule,
    MatPaginatorModule,
    RouterLinkWithHref,
    StoreModule.forRoot({ userSession: userSessionReducer }),
  ],
  providers: [
    ...getFormatsDatePickerProvider(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorProvider },
    provideRouter(APP_ROUTES),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
