import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserTypeLogin } from 'src/app/pages/login/login.model';
import { insert, reset } from 'src/app/store/actions/user-session.action';

const KEY_TOKEN_USER = 'KEY_TOKEN_USER';
const KEY_DATA_USER = 'KEY_DATA_USER';

export type SessionDataUserType = {
  userId: number;
  userType: UserTypeLogin;
};

@Injectable({
  providedIn: 'root',
})
export class SessionUserService {
  constructor(private store: Store<{ userSession: SessionDataUserType }>) {}

  public setSessionTokenInBrowser = (token: string): void =>
    localStorage.setItem(KEY_TOKEN_USER, token);

  public getSessionTokenInBrowser = () => localStorage.getItem(KEY_TOKEN_USER);

  public userIsLogged = (): boolean =>
    this.getSessionTokenInBrowser() !== null &&
    this.getSessionTokenInBrowser() !== '';

  public removeSessionUser = () => {
    localStorage.clear();
    this.store.dispatch(reset());
  };

  public setSessionDataInBrowser = (data: SessionDataUserType) => {
    this.store.dispatch(insert(data));
    localStorage.setItem(KEY_DATA_USER, JSON.stringify(data));
  };

  public getSessionDataInBrowser = (): SessionDataUserType | null => {
    const value = localStorage.getItem(KEY_DATA_USER);
    if (value !== null && value !== '')
      return JSON.parse(value) as SessionDataUserType;

    return null;
  };
}
