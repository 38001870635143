<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>
<h1 mat-dialog-title class="register-dialog__title">{{ title }}</h1>
<div
  mat-dialog-content
  class="register-dialog__content"
  [ngClass]="{ 'register-dialog__loading': isLoading }"
>
  <form [formGroup]="form" class="register-dialog__content-form">
    <mat-form-field class="register-dialog__content-form__fullname">
      <mat-icon matTextPrefix>badge</mat-icon>
      <mat-label>Nome completo</mat-label>
      <input matInput formControlName="fullName" />
      <mat-error *ngIf="fieldIsInvalid('fullName')" class="mat-error">{{
        getErrorGenericMessage("fullName")
      }}</mat-error>
    </mat-form-field>

    <div class="register-dialog__content-form__group">
      <mat-form-field>
        <mat-icon matTextPrefix>badge</mat-icon>
        <mat-label>CPF</mat-label>
        <input
          matInput
          placeholder="Ex: 000.000.000-00"
          formControlName="cpf"
          mask="000.000.000-00"
          type="text"
        />
        <mat-error *ngIf="fieldIsInvalid('cpf')" class="mat-error">{{
          getErrorGenericMessage("cpf")
        }}</mat-error>
      </mat-form-field>

      <mat-form-field class="date-birth">
        <mat-icon matTextPrefix>cake</mat-icon>
        <mat-label>Data de nascimento</mat-label>
        <input matInput [matDatepicker]="dp" formControlName="dateBirth" />
        <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="register-dialog__content-form__group">
      <mat-form-field>
        <mat-icon matTextPrefix>smartphone</mat-icon>
        <mat-label>Telefone</mat-label>
        <input
          matInput
          placeholder="Ex: (99) 99999-9999"
          formControlName="cellphone"
          mask="(00) 00000-0000"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-icon matTextPrefix>mail</mat-icon>
        <mat-label>E-mail</mat-label>
        <input
          matInput
          placeholder="Ex: rosi@exemplo.com"
          formControlName="email"
        />
      </mat-form-field>
    </div>

    <div class="register-dialog__content-form__group">
      <mat-checkbox
        class="add-category-dialog__content-form__checkbox"
        formControlName="isDisabled"
      >
        Desativar cliente
      </mat-checkbox>
    </div>
  </form>
</div>
<div mat-dialog-actions [align]="isTeenyMobile ? 'center' : 'end'">
  <button
    mat-raised-button
    (click)="submit()"
    class="register-dialog__actions"
    color="accent"
    [disabled]="formIsInvalid()"
  >
    {{ labelAction }}
  </button>
  <button mat-raised-button (click)="close()" class="register-dialog__actions">
    Cancelar
  </button>
</div>
