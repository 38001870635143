<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>

<div
  class="add-container-availability"
  [ngClass]="{ 'add-container-availability__loading': isLoading }"
>
  <h1 class="add-container-availability__title">Adicionar período de trabalho</h1>

  <h3 class="add-container-availability__description">
    Adicione a localização e as datas que serão inseridas a disponbilidade de
    seu horário.<br>Somente será possível inserir entradas e saídas para datas nas quais não
    possuem horários cadastrados.
  </h3>

  <form [formGroup]="form" class="add-container-availability__form">
    <div class="add-container-availability__form-box">
      <div class="add-container-availability__form-box-group">
        <h2 class="add-container-availability__form-box-subtitle">
          Localização e periodo de trabalho
        </h2>
        <mat-form-field>
          <mat-label>Localização</mat-label>
          <mat-select
            formControlName="location"
            (ngModelChange)="onChangeLocationForEnabledPeriod()"
          >
            <mat-option
              *ngFor="let location of locations"
              [value]="location.value"
            >
              {{ location.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="fieldIsInvalid('location')" class="mat-error">{{
            getErrorGenericMessage("location")
          }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Periodo de trabalho</mat-label>
          <mat-date-range-input
            [rangePicker]="picker"
            [disabled]="disabledPeriod"
          >
            <input
              matStartDate
              placeholder="Data inicial"
              formControlName="rangePeriodStart"
              (dateChange)="onChangePeriodDates()"
            />
            <input
              matEndDate
              placeholder="Data final"
              formControlName="rangePeriodEnd"
              (dateChange)="onChangePeriodDates()"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error *ngIf="rangePeriodIsInvalid()" class="mat-error">{{
            getErrorGenericMessage("rangePeriodStart") === ""
              ? getErrorGenericMessage("rangePeriodEnd")
              : getErrorGenericMessage("rangePeriodStart")
          }}</mat-error>
        </mat-form-field>
      </div>

      <div>
        <h2 class="add-container-availability__form-box-subtitle">
          Entradas e saídas
        </h2>
        <ng-template
          ngFor
          let-element
          [ngForOf]="inputsOutputs.controls"
          let-i="index"
          style="width: 100%"
        >
          <div
            class="add-container-availability__form-box-input-output"
            *ngIf="i === inputsOutputs.controls.length - 2"
          >
            <mat-form-field>
              <mat-label>Entrada</mat-label>
              <input
                matInput
                type="time"
                [formControl]="$any(inputsOutputs.controls[i])"
              />
              <mat-error
                *ngIf="fieldInputOrOutputInvalid(inputsOutputs.controls[i])"
                class="mat-error"
                >{{ "Preencha este campo!" }}</mat-error
              >
            </mat-form-field>
            <mat-form-field>
              <mat-label>Saída</mat-label>
              <input
                matInput
                type="time"
                [formControl]="$any(inputsOutputs.controls[i + 1])"
              />
              <mat-error
                *ngIf="fieldInputOrOutputInvalid(inputsOutputs.controls[i + 1])"
                class="mat-error"
                >{{ "Preencha este campo!" }}</mat-error
              >
            </mat-form-field>
            <button
              mat-fab
              class="container-client__title-action"
              color="accent"
              (click)="createInputAndOutput()"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>

          <div
            class="add-container-availability__form-box-input-output"
            *ngIf="i !== inputsOutputs.controls.length - 2 && i % 2 === 0"
          >
            <mat-form-field>
              <mat-label>Entrada</mat-label>
              <input
                matInput
                type="time"
                [formControl]="$any(inputsOutputs.controls[i])"
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Saída</mat-label>
              <input
                matInput
                type="time"
                [formControl]="$any(inputsOutputs.controls[i + 1])"
              />
            </mat-form-field>
            <button
              mat-fab
              class="container-client__title-action"
              color="accent"
              (click)="deleteInputAndOutput(i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="add-container-availability__actions">
      <button mat-raised-button class="submit" (click)="back()">
        Voltar
      </button>
      <button
        mat-raised-button
        class="submit"
        color="accent"
        (click)="submit()"
        [disabled]="formIsInvalid()"
      >
        Salvar
      </button>
    </div>
  </form>
</div>
