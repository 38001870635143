import { createReducer, on } from '@ngrx/store';
import { insert, reset } from '../actions/user-session.action';
import { SessionDataUserType } from 'src/app/shared/services/session-user/session-user.service';
import { UserTypeLogin } from 'src/app/pages/login/login.model';

export const initialState: { value: SessionDataUserType | null } = {
  value: { userId: -1, userType: UserTypeLogin.USER },
};

export const userSessionReducer = createReducer(
  initialState,
  on(insert, (state, session) => {
    return {
      ...state,
      value: session,
    };
  }),
  on(reset, (state) => {
    return {
      ...state,
      value: null,
    };
  })
);
