import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AvailabilityService } from './availability.service';
import {
  CalendarAvailability,
  CalendarAvailabilityRow,
} from './availability.model';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';
import { Location } from '../client/client.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.sass'],
})
export class AvailabilityComponent implements OnInit {
  public isLoading = false;
  public items: CalendarAvailabilityRow[] = [];

  public form: FormGroup = new FormGroup({});

  public locations: {
    name: string;
    value: Location;
  }[] = [];

  constructor(
    private service: AvailabilityService,
    private snackBarService: SnackbarService,
    private dateFormatService: DateFormatService,
    private router: Router
  ) {}

  getItems = (startDate: Date, endDate: Date, locationId: number) => {
    this.isLoading = true;

    this.service.getPeriod(startDate, endDate, locationId).subscribe({
      next: (response: CalendarAvailability[]) => {
        if (!!response && response.length > 0)
          this.items = this.service.castTimesToRows(
            response,
            startDate,
            endDate
          );
        else this.items = [];
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBarService.showMessageError();
      },
      complete: () => {
        if (this.items.length === 0)
          this.snackBarService.showMessageSuccess(
            'Não possui horários para o período selecionado!'
          );
        this.isLoading = false;
      },
    });
  };

  private initialize = () => {
    this.isLoading = true;
    this.service.getAllLocations().subscribe({
      next: (locations: Location[]) => {
        if (!!locations && locations?.length > 0) {
          locations.forEach((location) => {
            this.locations.push({
              name: `${location.street}, ${location.number} - ${location.district}, ${location.city} - ${location.state}`,
              value: location,
            });
          });

          this.form.get('location')?.setValue(this.locations[0].value);

          this.isLoading = false;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.snackBarService.showMessageError();
        console.log(error);
        this.isLoading = false;
      },
      complete: () => {
        if (this.locations.length === 0) {
          this.snackBarService.showMessageSuccess(
            'Você não possui localizações cadastradas!'
          );
          this.isLoading = false;
        }
      },
    });
  };

  ngOnInit() {
    this.form = new FormGroup({
      location: new FormControl([Validators.required]),
      rangePeriodStart: new FormControl([Validators.required]),
      rangePeriodEnd: new FormControl([Validators.required]),
    });

    this.initialize();
  }

  public getInputOrOutputFormat = (startDate: Date, endDate: Date) =>
    `Entada: ${this.dateFormatService.format(
      startDate,
      'LT'
    )} - Saída: ${this.dateFormatService.format(endDate, 'LT')}`;

  public getDateFormatAccordtion = (date: Date) =>
    this.dateFormatService.format(date, 'L');

  private getControl = (field: string): AbstractControl | null =>
    this.form.get(field);

  public fieldIsInvalid = (field: string): boolean => {
    const fieldControl = this.getControl(field);

    if (fieldControl) return fieldControl.invalid;
    return false;
  };

  public getErrorGenericMessage = (field: string): string => {
    const fieldControl = this.getControl(field);

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';

      if (fieldControl.invalid) return 'Campo inválido!';
    }

    return '';
  };

  public formIsInvalid = () => {
    const location = this.getControl('location');
    if (!location?.touched) this.form.markAllAsTouched();

    const locationIsValid =
      location?.getRawValue() !== null && !!location?.getRawValue();

    return this.form.invalid || !locationIsValid;
  };

  public rangePeriodIsInvalid = () =>
    this.fieldIsInvalid('rangePeriodStart') &&
    this.fieldIsInvalid('rangePeriodEnd');

  public submit = () => {
    const rangePeriodStart = this.getControl('rangePeriodStart')?.value as Date;
    const rangePeriodEnd = this.getControl('rangePeriodEnd')?.value as Date;
    const location = this.getControl('location')?.value as Location;
    this.getItems(rangePeriodStart, rangePeriodEnd, location.id);
  };

  public delete = (date: Date, locationId: number) => {
    this.isLoading = true;
    this.service.delete(locationId, date).subscribe({
      next: () => {
        this.snackBarService.showMessageSuccess(
          'Exclusão realizada com sucesso!'
        );
        this.getItems(
          this.items[0].date,
          this.items[this.items.length - 1].date,
          locationId
        );
      },
      error: (error) => {
        console.log(error);
        this.snackBarService.showMessageError();
        this.isLoading = false;
      },
    });
  };

  public addAvailability = () => {
    this.router.navigateByUrl('/admin-area/availability/add');
  };

  public editAvailability = (date: Date, location: Location) => {
    this.router.navigateByUrl('/admin-area/availability/edit', {
      state: {
        date,
        location,
      },
    });
  };
}
