import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { provideNgxMask } from 'ngx-mask';
import { HttpClientInterceptor } from 'src/app/shared/services/interceptor/http-client-interceptor.service';
import { HistoricComponent } from './historic/historic.component';
import { ServicesComponent } from './services/services.component';
import { SchedulingComponent } from './scheduling/scheduling.component';

export const ROUTES_USER: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'historic',
    component: HistoricComponent,
  },
  {
    path: 'services',
    component: ServicesComponent,
  },
  {
    path: 'scheduling',
    component: SchedulingComponent,
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES_USER)],
  exports: [RouterModule],
  providers: [
    provideNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
  ],
})
export class UserRoutingModule {}
