import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '../../admin-area/client/client.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Category } from '../../admin-area/category/category.model';
import {
  AvailableDaysInTheMonthResponseDTO,
  SchedulingTimesResponseDTO,
} from './scheduling.model';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';

const URL_LOCATION = 'location';

const URL_CATEGORY_SERVICE = 'category-service';

const URL_CALENDAR_AVAILABILITY = 'calendar-availability';

const URL_SCHEDULING_TIMES = 'event/user/scheduling-times';

@Injectable({
  providedIn: 'root',
})
export class SchedulingService {
  constructor(
    private httpClient: HttpClient,
    private dateFormatService: DateFormatService
  ) {}

  public getAllLocations = (): Observable<Location[]> =>
    this.httpClient.get<Location[]>(URL_LOCATION).pipe();

  public getAllServices = (): Observable<Category[]> =>
    this.httpClient.get<Category[]>(URL_CATEGORY_SERVICE).pipe();

  public getMonthAvaliable = (
    locationID: number
  ): Observable<AvailableDaysInTheMonthResponseDTO> => {
    const url = `${URL_CALENDAR_AVAILABILITY}/month-available`;
    return this.httpClient
      .get<AvailableDaysInTheMonthResponseDTO>(url, {
        params: new HttpParams().append('locationID', locationID),
      })
      .pipe();
  };

  public getSchedulingTimes = (
    locationID: number,
    serviceID: number,
    date: Date
  ): Observable<SchedulingTimesResponseDTO> =>
    this.httpClient
      .get<SchedulingTimesResponseDTO>(URL_SCHEDULING_TIMES, {
        params: new HttpParams()
          .append('id-service', serviceID)
          .append('id-location', locationID)
          .append('date', this.dateFormatService.castDateForShortString(date)),
      })
      .pipe();
}
