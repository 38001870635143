import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category, ServiceCategory } from './category.model';

const URL_CATEGORY = 'category-service';

const URL_SERVICE = 'service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private httpClient: HttpClient) {}

  public getAll = (): Observable<Category[]> =>
    this.httpClient.get<Category[]>(URL_CATEGORY).pipe();

  public deleteService = (id: number) => {
    const params = new HttpParams().set('id', id);

    return this.httpClient.delete(URL_SERVICE, { params }).pipe();
  };

  public deleteCategory = (id: number) => {
    const params = new HttpParams().set('id', id);

    return this.httpClient.delete(URL_CATEGORY, { params }).pipe();
  };

  public insertService = (idCategory: number, service: ServiceCategory) => {
    return this.httpClient
      .post(`${URL_SERVICE}\\${idCategory}`, JSON.stringify(service))
      .pipe();
  };

  public updateService = (idCategory: number, service: ServiceCategory) => {
    return this.httpClient
      .put(`${URL_SERVICE}\\${idCategory}`, JSON.stringify(service))
      .pipe();
  };

  public createCategory = (category: Category) => {
    return this.httpClient.post(URL_CATEGORY, JSON.stringify(category)).pipe();
  };

  public updateCategory = (category: Category) => {
    return this.httpClient.put(URL_CATEGORY, JSON.stringify(category)).pipe();
  };
}
