import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';

import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { LoginService } from './login.service';
import {
  StatusUser,
  UserAuthenticationResponse,
  UserTypeLogin,
} from './login.model';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-login',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  standalone: true,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent {
  constructor(
    private snackbarService: SnackbarService,
    private loginService: LoginService,
    private router: Router
  ) {}

  public hide = true;
  public isLoading = false;

  public form: FormGroup = new FormGroup({
    documentOrEmail: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  private getControl = (field: string): AbstractControl | null =>
    this.form.get(field);

  public fieldIsInvalid = (field: string): boolean => {
    const fieldControl = this.getControl(field);

    if (fieldControl) return fieldControl.invalid;
    return false;
  };

  public getErrorGenericMessage = (field: string): string => {
    const fieldControl = this.getControl(field);

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';

      if (fieldControl.invalid) return 'Campo inválido!';
    }

    return '';
  };

  public formIsInvalid = (): boolean => this.form.invalid;

  public submit = () => {
    if (!this.formIsInvalid()) {
      this.isLoading = true;

      this.loginService
        .userAuthentication({
          documentOrEmail: this.getControl('documentOrEmail')?.value,
          password: this.getControl('password')?.value,
        })
        .subscribe({
          next: (response: UserAuthenticationResponse) => {
            if (response.status === StatusUser.INVALID_CREDENTIALS) {
              this.snackbarService.showMessageError(
                'Usuário ou senha inválida! Tente novamente!'
              );
              this.getControl('password')?.setValue('');
            } else if (response.status === StatusUser.UNCONFIRMED_CONTACT)
              this.router.navigateByUrl(
                `/confirmation-contact/${response.newUserID}`
              );

            this.isLoading = false;
          },
          error: (error) => {
            this.isLoading = false;
            this.snackbarService.showMessageError();
            console.log('error - ', error);
          },
        });
    }
  };

  public redirect = (url: string) => {
    this.router.navigateByUrl('/register');
  };
}
