<div class="home-container">
  <div class="home-container__box">
    <h2 class="home-container__box-sub-title">
      Seja bem vinda(o) ao Espaço Rosiane Almeida
    </h2>

    <h1 class="home-container__box-title">Encontre uma nova versão de si!</h1>
  </div>

  <div class="home-container__principal">
    <img src="../../../../assets/home.png" alt="Imagem da home" />
    <button
      mat-raised-button
      class="submit"
      color="accent"
      (click)="redirectSchedule()"
    >
      AGENDAR
      <mat-icon> keyboard_double_arrow_right </mat-icon>
    </button>
  </div>
</div>
