import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAbout } from './about.model';

const URL = 'about';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  constructor(private httpClient: HttpClient) {}

  public get = (): Observable<IAbout> =>
    this.httpClient.get<IAbout>(URL).pipe();

  public post = (about: IAbout): Observable<IAbout> =>
    this.httpClient.post<IAbout>(URL, JSON.stringify(about)).pipe();
}
