import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Category } from '../category.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MAX_WIDTH_FIXED_TEENY } from 'src/app/shared/validators/breakpoints';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CategoryService } from '../category.service';
import { Observable } from 'rxjs';

type AddServiceDialogData = {
  category: Category;
  showOrHideLoading: (show: boolean) => {};
  showSuccessMessage: (message: string) => {};
  showErrorMessage: (message: string) => {};
};

@Component({
  selector: 'app-add-category-dialog',
  templateUrl: './add-category-dialog.component.html',
  styleUrls: ['./add-category-dialog.component.sass'],
})
export class AddCategoryDialogComponent implements OnInit, AfterViewInit {
  public isLoading = false;
  public title = 'Adicionar categoria';
  public isTeenyMobile = false;
  public labelAction = 'Adicionar';
  public isEdit = false;
  public clientVisible = true;
  public unscheduledEvent = false;

  public form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<AddCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddServiceDialogData,
    private observer: BreakpointObserver,
    private categoryService: CategoryService
  ) {}

  private showLoading = (show: boolean) => (this.isLoading = show);

  private buildFormAndLabels = () => {
    this.isEdit = !!this.data?.category;

    if (!!this.data?.category) {
      this.form.setValue({
        name: this.data.category.description,
      });
      this.clientVisible = this.data.category.clientVisible;
      this.unscheduledEvent = this.data.category.unscheduledEvent;
      this.labelAction = 'Editar';
      this.title = 'Editar categoria';
    } else {
      this.labelAction = 'Adicionar';
      this.title = 'Adicionar categoria';
    }
  };

  ngOnInit() {
    this.buildFormAndLabels();
  }

  ngAfterViewInit(): void {
    this.observer.observe([MAX_WIDTH_FIXED_TEENY]).subscribe((res) => {
      this.isTeenyMobile = res.matches;
    });
  }

  public fieldIsInvalid = (field: string): boolean => {
    const fieldControl = this.getControl(field);

    if (fieldControl) return fieldControl.invalid;
    return false;
  };

  private getControl = (field: string): AbstractControl | null =>
    this.form.get(field);

  public getErrorGenericMessage = (field: string): string => {
    const fieldControl = this.getControl(field);

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';

      if (fieldControl.invalid) return 'Campo inválido!';
    }

    return '';
  };

  public formIsInvalid = (): boolean => this.form.invalid;

  public submit = () => {
    this.showLoading(true);

    let response: Observable<any>;

    if (this.isEdit)
      response = this.categoryService.updateCategory({
        ...this.data.category,
        clientVisible: this.clientVisible,
        description: this.form.get('name')?.value,
        unscheduledEvent: this.unscheduledEvent,
      });
    else
      response = this.categoryService.createCategory({
        clientVisible: this.clientVisible,
        description: this.form.get('name')?.value,
        unscheduledEvent: this.unscheduledEvent,
        services: [],
        id: -1,
      });

    response.subscribe({
      next: () => {
        this.data.showSuccessMessage(
          `${this.isEdit ? 'Editado' : 'Adicionado'} categoria com sucesso!`
        );
        this.close();
      },
      error: () =>
        this.data.showErrorMessage(
          `Erro ao ${this.isEdit ? 'editar' : 'adicionar'} categoria!`
        ),
      complete: () => this.showLoading(false),
    });
  };

  public close = () => {
    this.dialogRef.close();
  };
}
