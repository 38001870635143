import { SnackbarService } from './../../../shared/services/snackbar/snackbar.service';
import {
  Category,
  ServiceCategory,
} from '../../admin-area/category/category.model';
import { Location } from '../../admin-area/client/client.model';
import { SchedulingService } from './scheduling.service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MAX_WIDTH_MEDIUM } from 'src/app/shared/validators/breakpoints';
import { DateFormatService } from 'src/app/shared/services/date-format/date-format.service';
import { SchedulingTimesResponseDTO } from './scheduling.model';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogConfirmationComponent,
  DialogConfirmationData,
} from './dialog-confirmation/dialog-confirmation.component';
import { PeriodInputOutput } from '../../admin-area/availability/add-availability/add-availability.model';
import { CalendarService } from '../../admin-area/calendar/calendar.service';
import { SessionDataUserType } from 'src/app/shared/services/session-user/session-user.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Moment } from 'moment';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.sass'],
})
export class SchedulingComponent implements OnInit, AfterViewInit, OnDestroy {
  private storeSubscription: Subscription | null = null;
  public locations: Location[] = [];

  private dateLimitFilter: Date | null = null;
  private userID: number = -1;
  private locationSelected: Location | null = null;
  private serviceSelected: ServiceCategory | null = null;
  private categorySelected: Category | null = null;
  public dateSelectedLabel: Date = new Date();

  public categories: Category[] = [];

  private locationIsLoading = false;
  private serviceIsLoading = false;
  private dateIsLoading = false;
  private submitIsLoading = false;
  private submitConfirmationIsLoading = false;

  public dividirVertical = false;
  public startDate = new Date();
  private datesFilter: Date[] = [];

  public schedulingTimes: SchedulingTimesResponseDTO | null = null;

  locationFormControl = new FormControl([Validators.required]);
  categoriesFormControl = new FormControl([Validators.required]);
  dateFormControl = new FormControl(new Date(), [Validators.required]);

  constructor(
    private schedulingService: SchedulingService,
    private snackbarService: SnackbarService,
    private observer: BreakpointObserver,
    private dateFormatService: DateFormatService,
    private dialog: MatDialog,
    private calendarService: CalendarService,
    private store: Store<{ userSession: SessionDataUserType }>
  ) {}

  ngOnDestroy(): void {
    if (this.storeSubscription) this.storeSubscription.unsubscribe();
  }

  private getAllLocations = () => {
    this.locationIsLoading = true;
    this.schedulingService.getAllLocations().subscribe({
      next: (response) => {
        this.locations = [];
        this.locations = response;
        this.locationIsLoading = false;
      },
      error: () => {
        this.snackbarService.showMessageError(
          'Falha ao buscar as localizações cadastrados. Contate Rosiane Almeida para dúvidas!'
        );
        this.locationIsLoading = false;
      },
    });
  };

  private getAllServices = () => {
    this.serviceIsLoading = true;
    this.schedulingService.getAllServices().subscribe({
      next: (response) => {
        this.categories = [];

        response.forEach((categorie) => {
          if (categorie.clientVisible && !categorie.unscheduledEvent)
            this.categories.push(categorie);
        });

        this.serviceIsLoading = false;
      },
      error: () => {
        this.snackbarService.showMessageError();
        this.serviceIsLoading = false;
      },
    });
  };

  public isLoading = () =>
    this.locationIsLoading ||
    this.serviceIsLoading ||
    this.dateIsLoading ||
    this.submitIsLoading ||
    this.submitConfirmationIsLoading;

  ngAfterViewInit(): void {
    this.observer
      .observe([MAX_WIDTH_MEDIUM])
      .subscribe((res) => (this.dividirVertical = !res.matches));
  }

  getUserID = () => {
    this.storeSubscription = this.store
      .select('userSession')
      .subscribe((data: any) => {
        if (data.value !== null) this.userID = data.value.userId;
      });
  };

  private buildDateLimitFilter = () => {
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 1);
    this.dateLimitFilter = dateNow;
  };

  ngOnInit(): void {
    this.buildDateLimitFilter();
    this.dateFormControl.disable();
    this.getAllLocations();
    this.getAllServices();
    this.getUserID();
  }

  getLabelLocation = (location: Location) =>
    `${location.street}, ${location.number} - ${location.district}, ${location.city} - ${location.state}`;

  filterDates = (d: Date | null): boolean => {
    if (d !== null && this.dateLimitFilter !== null) {
      if (d instanceof Date) {
        if (d !== null && d < this.dateLimitFilter) return false;

        return !!this.datesFilter.find(
          (date) => d?.getTime() === date.getTime()
        );
      } else {
        const dateMoment = d as Moment;
        if (dateMoment.toDate() < this.dateLimitFilter) return false;

        return !!this.datesFilter.find(
          (date) => dateMoment?.toDate()?.getTime() === date.getTime()
        );
      }
    }

    return false;
  };

  private enableOrDisableForm = (enable: boolean) => {
    const inputs = [
      this.dateFormControl,
      this.categoriesFormControl,
      this.locationFormControl,
    ];

    for (const input of inputs)
      if (enable) input.enable();
      else input.disable();
  };

  changeServiceOrDate = () => {
    const dateValid =
      this.dateFormControl.valid && this.dateFormControl.value !== null;
    const serviceValid =
      this.categoriesFormControl.valid &&
      this.categoriesFormControl.value !== null;
    const locationValid =
      this.locationFormControl.valid && this.locationFormControl.value !== null;

    if (dateValid && serviceValid && locationValid) {
      this.serviceSelected =
        this.categoriesFormControl.getRawValue() as any as ServiceCategory;
      this.locationSelected =
        this.locationFormControl.getRawValue() as any as Location;

      this.categories.forEach((categoryCurrent) => {
        categoryCurrent.services.forEach((serviceCurrent) => {
          if (serviceCurrent.id === this.serviceSelected?.id)
            this.categorySelected = categoryCurrent;
        });
      });

      if (this.dateFormControl.value !== null)
        this.dateSelectedLabel = new Date(this.dateFormControl.value);

      this.submitIsLoading = true;
      this.enableOrDisableForm(false);

      this.schedulingService
        .getSchedulingTimes(
          this.locationSelected.id,
          this.serviceSelected.id ?? -1,
          this.dateSelectedLabel
        )
        .subscribe({
          next: (response) => {
            this.schedulingTimes = response;
            this.submitIsLoading = false;
            this.enableOrDisableForm(true);
          },
          error: () => {
            this.snackbarService.showMessageError();
            this.submitIsLoading = false;
            this.enableOrDisableForm(true);
          },
        });
    }
  };

  changeLocation = () => {
    if (
      this.locationFormControl.valid &&
      this.locationFormControl.value !== null
    ) {
      this.dateFormControl.setValue(null);
      this.locationSelected = this.locationFormControl.value as any as Location;
      this.dateIsLoading = true;
      this.schedulingService
        .getMonthAvaliable(this.locationSelected.id)
        .subscribe({
          next: (response) => {
            this.dateFormControl.enable();
            if (response.date?.length === 0)
              this.snackbarService.showMessageSuccess(
                'Rosiane não tem horários disponíveis nesta localização!'
              );

            response.date.forEach((dateString) => {
              const date =
                this.dateFormatService.getDateByDateStringUTC(dateString);
              date.setHours(0, 0, 0, 0);
              this.datesFilter.push(date);
            });
            this.dateIsLoading = false;
          },
          error: () => {
            this.snackbarService.showMessageError();
            this.dateIsLoading = false;
            this.datesFilter = [];
            this.dateFormControl.disable();
          },
        });
    } else {
      this.dateFormControl.disable();
      this.locationSelected = null;
      this.datesFilter = [];
    }
  };

  public getInputOrOutputFormat = (startDate: string, endDate: string) =>
    `Entada: ${this.dateFormatService.format(
      this.dateFormatService.getDateByDateStringUTC(startDate),
      'LT'
    )} - Saída: ${this.dateFormatService.format(
      this.dateFormatService.getDateByDateStringUTC(endDate),
      'LT'
    )}.`;

  public getShortDateFormat = (date: Date) =>
    this.dateFormatService.castDateForShortString(date);

  public submitConfirmation = (
    category: Category | null,
    service: ServiceCategory | null,
    location: Location | null,
    startDate: Date
  ): void => {
    if (category !== null && service !== null && location !== null) {
      this.submitConfirmationIsLoading = true;
      this.calendarService
        .post({
          id: -1,
          startDateHour: startDate,
          idClient: this.userID,
          category: category,
          service,
          location,
        })
        .subscribe({
          next: () => {
            this.snackbarService.showMessageSuccess('Agendado com sucesso!');
            this.changeServiceOrDate();
            this.submitConfirmationIsLoading = false;
          },
          error: (error) => {
            this.submitConfirmationIsLoading = false;

            if (error?.error?.message === 'CALENDAR_ERROR_002')
              this.snackbarService.showMessageError(
                'Este horário já está ocupado por outro evento! Recarrega a página e tente novamente!'
              );
            else if (error?.error?.message === 'CALENDAR_ERROR_001')
              this.snackbarService.showMessageError(
                'Não é possível marcar neste horário pois não há disponibildiade de horário cadastrada! Contate Rosiane Almeida para dúvidas!'
              );
            else {
              this.snackbarService.showMessageError();
              console.log(error);
            }
          },
        });
    }
  };

  public openDialog = (period: PeriodInputOutput) => {
    this.dialog.open(DialogConfirmationComponent, {
      data: {
        service: this.serviceSelected,
        location: this.locationSelected,
        date: this.dateSelectedLabel,
        period,
        submit: () =>
          this.submitConfirmation(
            this.categorySelected,
            this.serviceSelected,
            this.locationSelected,
            period.input
          ),
      } as DialogConfirmationData,
    });
  };

  public dateFormInvalid = (): boolean => this.dateFormControl.invalid;
}
