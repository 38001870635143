import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { CategoryService } from './category.service';
import { Category, ServiceCategory } from './category.model';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { AddServiceDialogComponent } from './add-service-dialog/add-service-dialog.component';
import { MAX_WIDTH_FIXED_SIDE_BAR } from 'src/app/shared/validators/breakpoints';
import { AddCategoryDialogComponent } from './add-category-dialog/add-category-dialog.component';
import { TimeService } from 'src/app/shared/utils/time-service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.sass'],
})
export class CategoryComponent implements OnInit, AfterViewInit {
  public isLoading: boolean = false;
  public heigthDialog = '';
  private widthRegisterDialog = '50%';

  public categories: Category[] = [];

  constructor(
    private service: CategoryService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    private observer: BreakpointObserver,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.observer.observe([MAX_WIDTH_FIXED_SIDE_BAR]).subscribe((res) => {
      if (res.matches) {
        this.widthRegisterDialog = '100%';
        this.heigthDialog = '100%';
      }
    });

    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.buildCategories();
  }

  private buildCategories = () => {
    this.isLoading = true;

    this.service.getAll().subscribe({
      next: (response) => {
        this.categories = response;
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => (this.isLoading = false),
    });
  };

  public deleteService = (id?: number) => {
    if (!!id) {
      this.isLoading = true;

      this.service.deleteService(id).subscribe({
        next: () => {
          this.buildCategories();
          this.snackbarService.showMessageSuccess(
            'Excluído serviço com sucesso!'
          );
        },
        error: (error) => {
          this.isLoading = false;
          if (error.status === 403)
            this.snackbarService.showMessageError(
              'Não é possível excluir pois há eventos marcados utilizando este serviço. Caso necessário deixe invisível para seus clientes',
              5000
            );
          else this.snackbarService.showMessageError();
        },
        complete: () => (this.isLoading = false),
      });
    }
  };

  public deleteCategory = (id?: number) => {
    if (!!id) {
      this.isLoading = true;

      this.service.deleteCategory(id).subscribe({
        next: () => {
          this.buildCategories();
          this.snackbarService.showMessageSuccess(
            'Excluído categoria com sucesso!'
          );
        },
        error: (error) => {
          this.isLoading = false;
          if (error.status === 403)
            this.snackbarService.showMessageError(
              'Não é possível excluir pois há eventos marcados utilizando os serviços desta categoria. Caso necessário deixe os serviços invisíveis para seus clientes'
            );
          else this.snackbarService.showMessageError();
        },
        complete: () => (this.isLoading = false),
      });
    }
  };

  private getPropertiesDataDefault = () => {
    return {
      showOrHideLoading: (show: boolean) => (this.isLoading = show),
      showSuccessMessage: (message: string) => {
        this.snackbarService.showMessageSuccess(message),
          this.buildCategories();
      },

      showErrorMessage: (message: string) =>
        this.snackbarService.showMessageError(message),
    };
  };

  private getPropertiesDataDefaultAddServiceDialogComponent = (
    id: number
  ): any => {
    return {
      idCategory: id,
      ...this.getPropertiesDataDefault(),
    };
  };

  public openRegisterService = (id?: number) => {
    if (!!id)
      this.dialog.open(AddServiceDialogComponent, {
        data: this.getPropertiesDataDefaultAddServiceDialogComponent(id),
        width: '100%',
        height: '90%',
      });
  };

  public openEditService = (service: ServiceCategory, id?: number) => {
    if (!!id)
      this.dialog.open(AddServiceDialogComponent, {
        data: {
          ...this.getPropertiesDataDefaultAddServiceDialogComponent(id),
          service,
        },
        width: '100%',
        height: '85%',
      });
  };

  public openCreateCategory = () => {
    this.dialog.open(AddCategoryDialogComponent, {
      data: this.getPropertiesDataDefault(),
      width: '100%',
    });
  };

  public openEditCategory = (category: Category) => {
    this.dialog.open(AddCategoryDialogComponent, {
      data: { ...this.getPropertiesDataDefault(), category },
      width: '100%',
    });
  };

  public castDurationForLabel = (duration: number) =>
    TimeService.castSecondsInLabel(duration);
}
