<mat-spinner class="loading" color="warn" *ngIf="isLoading"></mat-spinner>

<div
  class="container-category"
  [ngClass]="{ 'container-category__loading': isLoading }"
>
  <h1 class="container-category__title">
    Categorias
    <button
      mat-fab
      class="container-client__title-action"
      color="accent"
      (click)="openCreateCategory()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </h1>

  <div class="container-category__cards" *ngFor="let category of categories">
    <mat-accordion class="container-category__cards-accordion">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ category.description }}</mat-panel-title>
        </mat-expansion-panel-header>

        <mat-card *ngFor="let service of category.services">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-title>{{ service.name }}</mat-card-title>
              <mat-card-subtitle>{{ service.description }}</mat-card-subtitle>
              <img mat-card-xl-image [src]="service.image" />
            </mat-card-title-group>
          </mat-card-header>

          <mat-card-content>
            <p>Duração: {{ castDurationForLabel(service.durationInSeconds) }}</p>
            <p>Forma de pagamento: {{ service.paymentFormat }}</p>
            <p>Valor: R$ {{ service.valueMonetary }}</p>
          </mat-card-content>

          <mat-card-actions
            align="end"
            class="container-category__cards-accordion__actions"
          >
            <button
              mat-mini-fab
              color="accent"
              (click)="openEditService(service, category.id)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-mini-fab
              color="accent"
              (click)="deleteService(service.id)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>

        <div class="container-category__cards-accordion__actions-category">
          <button
            mat-raised-button
            color="accent"
            (click)="deleteCategory(category.id)"
          >
            Remover categoria
          </button>
          <button
            mat-raised-button
            color="accent"
            (click)="openEditCategory(category)"
          >
            Editar categoria
          </button>
          <button
            mat-raised-button
            color="accent"
            (click)="openRegisterService(category.id)"
          >
            Adicionar serviço
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
