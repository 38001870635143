import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientService } from '../client.service';
import { MAX_WIDTH_FIXED_TEENY } from 'src/app/shared/validators/breakpoints';

type ClientRegisterData = {
  id?: number;
  fullName?: string;
  cpf?: string;
  email?: string;
  cellphone?: string;
  dateBirth?: string;
  isDisabled?: boolean;
  showOrHideLoading: (show: boolean) => {};
  showSuccessMessage: (message: string) => {};
  showErrorMessage: (message: string) => {};
};

@Component({
  selector: 'app-client-register-dialog',
  templateUrl: './client-register-dialog.component.html',
  styleUrls: ['./client-register-dialog.component.sass'],
})
export class ClientRegisterDialogComponent implements OnInit, AfterViewInit {
  public isLoading = false;
  public title = '';
  public labelAction = '';
  public isEdit = false;
  public isTeenyMobile = false;

  constructor(
    public dialogRef: MatDialogRef<ClientRegisterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClientRegisterData,
    private service: ClientService,
    private observer: BreakpointObserver
  ) {}

  private showLoading = (show: boolean) => (this.isLoading = show);

  ngAfterViewInit() {
    this.observer.observe([MAX_WIDTH_FIXED_TEENY]).subscribe((res) => {
      this.isTeenyMobile = res.matches;
    });
  }

  private buildForm = () => {
    if (!!this.data?.id) {
      this.isEdit = true;

      const { cellphone, cpf, dateBirth, email, fullName, isDisabled } =
        this.data;

      const firstName =
        fullName?.split(' ') && fullName?.split(' ').length > 0
          ? fullName.split(' ')[0]
          : fullName;

      const date = !!dateBirth ? new Date(dateBirth) : null;

      this.title = `Editar informações de ${firstName}`;
      this.labelAction = 'Editar';

      this.form.setValue({
        cellphone: cellphone ?? '',
        dateBirth: date ?? '',
        email: email ?? '',
        cpf,
        fullName,
        isDisabled,
      });

      this.form.get('cpf')?.disable();
      this.form.get('fullName')?.disable();

      if (!!dateBirth && dateBirth !== '')
        this.form.get('dateBirth')?.disable();
    } else {
      this.isEdit = false;
      this.labelAction = 'Cadastrar';
      this.title = 'Cadastrar cliente';
    }
  };

  ngOnInit() {
    this.buildForm();
  }

  public form: FormGroup = new FormGroup({
    fullName: new FormControl('', [Validators.required]),
    cpf: new FormControl('', [Validators.required]),
    cellphone: new FormControl(''),
    email: new FormControl(''),
    dateBirth: new FormControl(''),
    isDisabled: new FormControl(false),
  });

  private getControl = (field: string): AbstractControl | null =>
    this.form.get(field);

  public getErrorGenericMessage = (field: string): string => {
    const fieldControl = this.getControl(field);

    if (fieldControl) {
      if (fieldControl.hasError('required')) return 'Preencha este campo!';

      if (fieldControl.invalid) return 'Campo inválido!';
    }

    return '';
  };

  public fieldIsInvalid = (field: string): boolean => {
    const fieldControl = this.getControl(field);

    if (fieldControl) return fieldControl.invalid;
    return false;
  };

  close = () => this.dialogRef.close();

  public formIsInvalid = (): boolean => this.form.invalid;

  public submit = () => {
    this.showLoading(true);

    try {
      if (this.isEdit) {
        const { id } = this.data;
        const cellphone = this.getControl('cellphone')?.value;
        const email = this.getControl('email')?.value;

        this.service
          .update({
            id: id ?? -1,
            cellphone,
            email,
            disabled: Boolean(this.getControl('isDisabled')?.value),
          })
          .subscribe({
            next: () => {
              this.showLoading(false);
              this.data.showSuccessMessage('Editado o cliente com sucesso!');
              this.close();
            },
            error: (error) => {
              this.showLoading(false);
              if (error && error?.status === 409)
                this.data.showErrorMessage(
                  'Os dados inseridos já estão sendo utilizados no sistema!'
                );
              else
                this.data.showErrorMessage(
                  ''
                );
            },
          });
      } else {
        const cellphone = this.getControl('cellphone')?.value;
        const email = this.getControl('email')?.value;
        const fullName = this.getControl('fullName')?.value;
        const dateBirth =
          this.getControl('dateBirth')?.value !== ''
            ? this.getControl('dateBirth')?.value
            : null;
        const cpf = this.getControl('cpf')?.value;

        this.service
          .create({
            cellphone,
            fullName,
            email,
            dateBirth,
            cpf,
            disabled: Boolean(this.getControl('isDisabled')?.value),
          })
          .subscribe({
            next: () => {
              this.showLoading(false);
              this.data.showSuccessMessage('Criado o cliente com sucesso!');
              this.close();
            },
            error: (error) => {
              this.showLoading(false);
              if (error && error?.status === 409)
                this.data.showErrorMessage(
                  'Os dados inseridos já estão sendo utilizados no sistema!'
                );
              else this.data.showErrorMessage('');
            },
          });
      }
    } catch (error) {
      this.showLoading(false);
      this.data.showErrorMessage('');
      console.log(error);
    }
  };
}
